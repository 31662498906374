
#app {
  margin: 0 auto;
}

nav {
  padding: 20px 0 20px 0;
}

.webmap {
  height: 100%;
  width: 100%;
  outline: none;
}

.esri-view .esri-view-root {
  outline: none;
}

.esri-view .esri-view-surface {
  outline: none;
}