:root{
  --officialDarkBlue: #16548e;
  --officialLightGray: #eeeef1;
  --officialDarkGray: #474747;
  --hoverColor: #C3C3C3;
  --hoverColorDark: #555555;
}

.org1baseColor { background-color:#16548e; }
.org1baseBg { background-color:#16548e; }
.org1baseText { color:#16548e; text-shadow: 1px 1px #fff;}
.org1mainText {color:#16548e;}
.org1baseActive { color:#16548e !important; border-bottom: 4px solid #16548e !important;}
.org2buttonIcon { color:#333 !important;}

.org2baseColor { background-color: #ffc13c !important; }
.org2baseBg { background-color:#222 !important; }
.org2baseText { color:#333 !important; text-shadow: 1px 1px #222;}
.org2mainText { color:#FFC13C !important; text-shadow: 1px 1px #222;}
.org2baseActive { color:#FFC13C !important; border-bottom: 4px solid #FFC13C !important;}
.org2buttonIcon { color:#333 !important;}
.org2basetextHover:hover { color: #FFC13C !important; text-decoration: none !important;}
.org2basebuttonHover:hover { background-color: #333 !important; text-decoration: none !important;}

.org3base { background-color:#0062AF; }
.org3baseColor { background-color: #0062AF !important; }
.org3baseBg { background-color:#0062AF !important; }
.org3baseText { color:#333 !important; text-shadow: 1px 1px #222;}
.org3mainText { color:#0062AF !important; text-shadow: 1px 1px #DDD;}
.org3baseActive { color:#0062AF !important; border-bottom: 4px solid #f7941d !important;}
.org3buttonIcon { color:#0062AF !important;}
.org3basetextHover:hover { color: #f7941d !important; text-decoration: none !important;}
.org3basebuttonHover:hover { background-color: #f7941d !important; text-decoration: none !important;}

.org4base { background-color:#009ddc; }
.org4baseColor { background-color: #009ddc !important; }
.org4baseBg { background-color:#009ddc !important; }
.org4baseText { color:#333 !important; text-shadow: 1px 1px #222;}
/* .org4mainText { color:#009ddc !important; text-shadow: 1px 1px #DDD;} */
.org4baseActive { color:#009ddc !important; border-bottom: 4px solid #333 !important;}
.org4buttonIcon { color:#009ddc !important;}
.org4basetextHover:hover { color: #333 !important; text-decoration: none !important;}
.org4basebuttonHover:hover { background-color: #333 !important; text-decoration: none !important;}
.org4contentMain {     background-position: center center;
  background-size: cover !important;
  background-blend-mode: multiply !important;}

@font-face {
  font-family: officialFont;
  src: url(../fonts/MYRIADPRO-REGULAR.woff);
}

@font-face {
  font-family: officialFontBold;
  src: url(../fonts/MYRIADPRO-BOLD.woff);
}

@font-face {
  font-family: officialFontSemiBold;
  src: url(../fonts/MYRIADPRO-SEMIBOLD.woff);
}

.mainBody{
  background-color:#E9E9EF;
  overflow: hidden;
}

.entityDetails{
  border-left: 2px solid #cacaca;
  padding: 0px !important;
  flex: auto;
  max-width: 100%;
}

.entity-edit-text {
  display: inline !important;
  width: 200px !important;
  margin-top: 5px;
  color: #095399 !important;
  font-weight: 600 !important;
}
.entity-edit-text.select {
  margin-left:4px;
}

.ARCGISFormControl{
  display: block;
  width: 250px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.275rem 0.55rem;
  font-size: 1rem;
  font-weight: 400;
  color: rgb(73, 80, 87);
  background-color: rgb(255, 255, 255);
  background-clip: padding-box;
  border-width: 0px 0px 2px;
  border-bottom-style: solid;
  border-bottom-color: rgb(189, 189, 189);
  border-right-style: initial;
  border-right-color: initial;
  border-left-style: initial;
  border-left-color: initial;
  border-top-style: initial;
  border-top-color: initial;
  border-radius: 0px;
  transition: none 0s ease 0s;
}

.entity-edit-text-comment{
  display: inline !important;
  width: 350px !important;
  height: 100px !important;
  margin-top: 5px;
  color: #095399 !important;
  font-weight: 600 !important;
  resize: none;
}

.entity-edit-checkbox {
  display: inline !important;
  position: relative; 
  top: 13px;
}

.multi-select{
  display: inline !important;
  width: 200px !important;
  color: #095399 !important;
  font-weight: 600 !important;
}

.dropdown-heading{
  width: 200px !important;
}

.dropdown-container{
  width: 200px !important;
  border-radius: 0px !important; 
  color: #095399 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border-bottom: 2px solid #bdbdbd !important;
  border-right: 0px !important;
  border-left: 0px !important;
  border-top: 0px !important;
}

.text-center.full{
  width: 100%;
  margin-left: -2px;
}

.text-left.full.medium.fadded{
  font-weight: 400;
  text-shadow: none;
  color: rgb(255 255 255 / 0.55);
  font-size: 13px;
}

.text-left.large{
  text-shadow: 0 0 2px #000;
  font-size: 15px;
  color: rgb(255 255 255 / 0.95);
  font-weight: 600;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.275rem 0.55rem;
  font-size: 1rem;
  font-weight: 400;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border-bottom: 2px solid #bdbdbd;
  border-right: 0px;
  border-left: 0px;
  border-top: 0px;
  border-radius: 0px;
  transition: none;
}

.form-control-sm {
  font-size: 14px !important;
}

.form-control:focus { 
  color: #495057;
  background-color: #fff;
  border: 0px;
  outline: 0;
  box-shadow: none;
  border-bottom: 2px solid #095399;
}

.mainHeader {
  font-size: 3.4rem;
  font-weight: 700;
  padding-top: 30px;
}
.subHeader {
  color: #adadad;
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 30px;
}
.subHeader2 {
  font-size: 19px;
  font-weight: 300;
  width: 65%;
  text-align: justify;
  margin: auto;
  margin-bottom: 40px;
  color: #848484;
}
.subHeader3{
  font-size: 18px;
  font-family: officialFontBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
  /* text-align: justify; */
  margin: auto;
  margin-bottom: 14px;
  color:var(--officialDarkBlue);
  text-transform: initial;
  margin-top: 10px !important;
}
.subHeader4{
  font-size: 13px;
  font-weight: 500;
  color: #757575;
  text-shadow: 1px 1px 1px #d8d8d8;
  height: 0px;
  margin-bottom: -10px;
  margin-top: 10px;
}

.subTextMinior{
  font-size:15px;
}

.mainContentHeader {
  color: #095399;
  font-size: 4rem;
  font-weight: 700;
  padding-top: 50px;
  text-shadow: 1px 1px #ffffff8a;
}
.mainContentDetail {
  color: #5d5d5d;
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 40px;
  text-shadow: 1px 1px #ffffff8a;
}
.mainContentDetailImages {
  color: #5d5d5d;
}
.accountHeader{
  font-weight: 600;
  font-size: 17px;
}

.paymentHistoryTable{
  overflow-y: auto;
  width: 100%;
  border: 1px solid rgb(234, 234, 234);
  margin-top: 1px;
  max-height: 235px;
  box-shadow: rgb(179, 179, 179) 0px 0px 1px 1px;
  background: #e9e9ef;
}

.loginDialogRow1 {
  border-left: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  background-color: rgba(239, 239, 240, 0.5);
}

.loginDialogRow2 {
  background-color: rgb(227, 227, 232);
  border-left: 2px solid #a7a7a7;
  border-right: 2px solid #a7a7a7;
}

.loginDialogRow3 {
  background-color: rgb(233, 233, 239);
  border-left: 2px solid #a7a7a7;
  border-right: 2px solid #a7a7a7;
}

.loginColumn {
  width: 390px;
}

.registrationColumn{
  width: 600px;
}

.loginTitle {
  flex: auto;
  align-items: center;
  flex-direction: column;
  background-color: rgba(239, 239, 240, 0.5);
  border-top: 2px solid #ffffff;
  border-left: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  border-color: #FFF;
}
.fullBorder{
  border: 2px solid #FFF;
}

.buttonText {
  font-size: 13px;
  font-weight: 500;
  font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif;
  text-transform: uppercase;
  cursor: pointer;
  margin: 0px;
  text-shadow: 1px 1px 1px #555;
  color: #FFF;
  padding-top: 2px;
}
.buttonText.withIcon{
  padding-left:2px;
}
.buttonText.withIcon.small{
  font-size: 12px;
  padding-top: 3px;
}
.buttonText.withIcon.date{
  font-size: 13px;
  margin-bottom: 10px !important;
  margin-top: 0px !important;
  width: 99px;
  text-align: center;
}
.buttonText:disabled{
  background-color: slateGray !important;
  cursor: no-drop !important;
}
.buttonText.xsmall{
  font-size: 12px;
  padding-left: 8px;
  padding-top: 3px;
}

.buttonText.small{
  font-size: 13px;
  margin-bottom: 2px !important;
}
.buttonText.extraSmall{
  font-size: 11px !important;
  position: relative;
  bottom: 2px;
}
.buttonText.center{
  width: 100%;
  text-align: center;
  align-items: center;
  align-self: center;
  justify-content: center;
  vertical-align: bottom;
  display: flex;
}
.buttonText.large{
  font-size: 15px;
  margin-bottom: 6px;
}
.buttonText.dark{
  margin: 0px;
  cursor: pointer;
  padding-left: 0px;
  font-size: 13px;
  padding-top: 2px;
  color: rgb(95 95 95);
  font-weight: 700;
  text-shadow: 0px 0px 1px #dcdcdc;
}
.labelText {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.75;
  font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif;
  text-transform: uppercase;
  margin: 0px;
  color: #676767;
}

.labelTextError {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.75;
  font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif;
  text-transform: uppercase;
  margin: 0px;
  color: #bd0000;
}

.labelText.account{
  text-transform: none;
}

.labelText.labelLarge {
  font-size: 17px;
  font-weight: 600;
  color: #333;
  margin-right: 14px;
}

.labelText.labelSmall{
  font-size: 12px;
  margin-left: 10px;
  color: #8d9aa9;
}

.labelText.labelSmall2{
  font-size: 14px;
  margin-left: 10px;
  color: #8c8c8c;
  font-weight: 600;
}

.labelText.active {
  font-weight: 600;
  line-height: 1.75;
  font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif;
  text-transform: uppercase;
  margin: 0px;
  color: #333;
}

.licensesTotal{
  align-self: flex-end;
  text-align: right;
}

.buttonAction.formClose{
  height: 34px;
  padding: 3px 8px 0px 8px;
  margin: 0px !important;
}

.buttonAction {
  border-radius: 0px;
  border: none;
  /* background-color: #16548e; */
  cursor: pointer;
  border: 2px solid #0000000f !important;
}

.highcharts-series-group{
  cursor: pointer;
}

.buttonAction.dehighlighted{
  background: #73879a !important;
}

.buttonAction.short{
  max-height: 35px;
  padding-top: 3px;
  padding-right: 15px;
}

.buttonAction.confirm{
  background-color: #378a66 !important;
}
.buttonAction.noLabel{
  padding: 7px;
  min-width: 40px !important;
}

.buttonActionLightBorder{
  border: 2px solid #b7b7b733 !important
}
.buttonAction.minWidth{
  min-width: 120px;
}

.buttonAction.details{ 
  height: 30px;
  margin: 0px 2px 0px 7px;
  width: 30px;
  padding: 0px 0px 0px 5px;
}

.noMargin{
  margin: 0px !important
}

.buttonAction.light{
  background: #6e859a;
}
.buttonAction.red{
  background-color: rgb(168, 8, 8);
}
.buttonAction.square{
  min-width: 44px;
  width: 28px;
}

.buttonActionNoBorder {
  border-radius: 0px;
  border: none;
  box-shadow: 1px 1px 1px 0px #31313169;
  background-color: #16548e;
  cursor: pointer;
  border: none !important;
  margin-left: 2px !important;
}
.esri-layer-list__child-toggle [class*=" esri-icon-"], .esri-layer-list__child-toggle .esri-building-level-picker__arrow-up, .esri-layer-list__child-toggle .esri-building-level-picker__arrow-down, .esri-layer-list__child-toggle .esri-building-phase-picker__arrow-left, .esri-layer-list__child-toggle .esri-building-phase-picker__arrow-right{
  display: none;
}

.esri-layer-list__child-toggle{
  display: none;
}

.esri-widget--button.active,
.esri-widget--button.active:hover,
.esri-widget--button.active:focus {
  cursor: default;
  background-color: #999696;
}
.esri-widget--button.active path,
.esri-widget--button.active:hover path,
.esri-widget--button.active:focus path {
  fill: #E4E4E4;
}

.buttonLayerLegend{
  width: 75px !important;
  height: 35px !important;
  padding: 1px !important;
  background: #dfdfe3 !important;
}
.buttonLayerLegend:focus{
  box-shadow: none;
  border: none !important;
} 

.layerListToggle{
  position: absolute;
  z-index: 1;
  right: 22px;
  top: 25px;
}

.basemapToggle{
  position: absolute;
  z-index: 1;
  right: 115px;
  top: 54px;
  box-shadow: 1px 1px 1px 1px #b7b7b7 !important;
}

.createTaskButton{
  position: absolute;
  z-index: 1;
  right: 105px;
  top: -7px;
  height: 32px;
  width: 71px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.60) !important;
  border: 1px solid #868686b3;
}

.buttonCreateTask{
  width: 65px !important;
  height: 30px !important;
  padding: 0px !important;
  background: #dfdfe3 !important;
  display: block;
  border: none !important;
  box-shadow: none !important;
}
.buttonCreateTask:focus{
  box-shadow: none;
  border: none !important;
} 

.noMultiMediaHeader{
  display : flex;
  align-content: center;
}

.marketIcon{
  margin-bottom: 7px;
  color: #eeeeee;
  background: #0a529ac4;
  border: 0.5px solid #FFF;
  transform: scale(1.3);
  box-shadow: 0px 0px 1px 1px #585858;
  padding: 2px;
}

.arrowIcon{
  color: #5f5f5f;
  transform: scale(1.1) !important;
  margin: 5px 0px 0px 0px !important;
  padding: 0px 0px 0px 0px;

}

.tagFilterRadioButton{
  color: #ffffff;
  transform: scale(0.7);
}

.buttonIcon{
  color: #b3bec8e0;
  transform: scale(0.9);
}
.buttonIcon.padded{
  margin: 0px 0px 4px 12px !important;
  color: #ddd;
  box-shadow: 0px 0px 1px 1px #aaa;
  transform: scale(1.05);
}
.buttonIcon.bright{
  margin-top: 4px;
  margin-left: 8px;
  transform: scale(0.8);
  margin-right: -8px;
  color: #FFF;
}
.buttonIcon.light{
  color: #b3bec8e0;
}

.buttonIcon.dark{
  color: #5f5f5f;
}
.buttonIcon.darkmain{
  margin-top: 8px;
  margin-left: 4px;
  color: #3c5873;
}

.buttonIcon.noLabel{
  margin:0px !important;
}

.buttonIcon.removeFieldMapping{
  margin: auto;
  display: block;
}

.buttonIcon.large{
  margin: 0px 0px 0px 0px !important;
  transform: scale(1.1) !important;
  padding: 0px 0px 0px 0px;
}
.buttonIcon.small{
  transform: scale(0.7) !important;
}
.buttonIconSmall{
  height: 21px !important;
  padding: 1px !important;
  margin: 0px !important;
  border: none !important;
  width: 42px
}

.buttonIcon.profileIcon{
  margin: 0px;
  color: rgb(101 116 130);
  transform: scale(1.2);
}

.btn.menuButton{
  padding: 4px;
  cursor: pointer;
  border-radius: 0px;
  margin-top: 10px;
  margin-right: 10px;
}
.btn.menuButton:hover {
  background-color: #b2babf !important;
  color: #fff !important;
  text-shadow: 1px 1px 1px #616161;
}

.icon{
  margin-right: 5px;
  margin-bottom: 3px;
  font-size: 28px !important;
  color: #095399d1;
}

.buttonSearch{
  margin-left: -1px !important;
  width: 90px;
  padding: 0px;
}

.buttonAdvancedSearchOptions{
  width: 45px;
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-width: 0px;
  height: 40px;
  align-items:center;
  display: flex;
  justify-content: center;
}

.buttonFaded{
  background: #808080 !important;
}

.buttonSwitch{
  min-width: 90px;
  padding: 2px 10px 0px 10px;
  display: flex;
  margin-left: 4px !important;
}

.buttonSwitch.alignText{
  padding-top: 6px;
}

.buttonLicense{
  padding: 4px 2px 5px 2px;
  min-width: 50px;
  border-radius: 0px;
  font-size: 1rem;
  box-shadow: inset 1px 1px 1px 1px #e4e4e482;
  margin: 0px 10px 0px 10px !important;
  max-width: 90px;
  text-align: center;
  border-width: 3px 28px 3px 3px !important;
  height: 25px;
  background-color: #5c7b98;
  border-color: #33333370 !important;
}
.buttonLicense:hover{
  background-color: #1783c5 !important;
  color: #fff !important;
  text-shadow: 1px 1px 1px #616161;
}

.buttonLicense.active{
  background-color: rgb(45, 121, 82);
  border-width: 3px 3px 3px 35px !important;
}

.buttonLicense.disabled{
  background-color: #777;
}

.licenseToggle{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
}
.licenseRow{
  padding: 10px;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.licenseRowInside{
  line-height: 2.8;
}
.licenseColumn{
  padding: 30px;
  display: flex;
  flex-direction: row;
  background: #e9e9ef;
  border: 2px solid #ffffff;
  box-shadow: 0px 0px 1px 1px #bfbfbf;
}
.licenseInput{
  max-width: 60px;
  line-height: 2 !important;
  height: 40px;
  margin: 2px;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  padding: 4px !important;
  padding-top: 6px !important;
}
.buttonActionSpecial {
  border-radius: 0px;
  font-size: 1rem;
  padding-top: 8px;
  border: none;
  box-shadow: 1px 1px 1px #8080809c;
  background-color: #099359;
  cursor: "pointer";
  margin: 5px !important;
}

.buttonActionIcon{
  min-height: 25px;
}

.buttonLogin{
    height: 54px !important;
    border: 2px solid #fff !important;
    box-shadow: none !important;
    border-top: none !important;
}

.signInRegister{
  border-top: 2px solid #ffffff !important;
  padding-top: 12px !important;
}

.buttonAccount{
  background-color: rgb(9, 83, 153);
  border-radius: 0px;
  margin: 0px 4px 0px 0px !important;
  /* min-width: 94px; */
  float: left;
}

.buttonAddFieldGisExport{
  background-color: rgb(9, 83, 153);
  border-radius: 0px;
  margin: 10px !important;
  /* min-width: 94px; */
  float: left;
}

.buttonFormFieldMappingSectionButtons{
  background-color: rgb(9, 83, 153);
  border-radius: 0px;
  margin-left: 10px !important;
  margin-bottom: 10px !important;
  /* min-width: 94px; */
}

.buttonRed{
  background-color: rgb(168, 8, 8);
  border-radius: 0px;
  margin: 0px 4px 0px 0px !important;
  /* min-width: 94px; */
  float: left;
}

.dataGroupsSaveButton{
  background-color: #378a66;
  border-radius : 0px;
  margin : 0px 4px 0px 0px !important;
  float : right;
  position: relative;
  bottom : 7px;
}

.buttonFilter{
  background-color: #abb7c3;
}

.buttonAccount.inputTight{
  margin-left: 0px !important;
  margin-top: 3px !important;
}

.buttonViewTaxCert{
  background-color: rgb(9, 83, 153);
  border-radius: 0px;
  min-width: 94px;
  width: 100%;
}

.buttonDeleteTaxCert{
  background-color: rgb(158, 10, 10);
  border-radius: 0px;
  width: 90px;
}

.buttonDeleteTaxCertDisabled{
  background-color: rgb(194, 107, 107);
  border-radius: 0px;
  width: 90px;
}

.buttonAccount.small{
  margin: 4px !important;
  margin-top: 0px !important;
  height: 35px;
  padding-top: 4px;
  padding-left: 4px;
}

.buttonAccount.vsmall{
  height: 34px;
  padding: 4px 8px 4px 4px;
  margin-left:10px !important;
}

.buttonSubmit{
  background-color: #b76100 !important;
}

.ccSave{
  display: initial !important;
  justify-content: center;
  text-align: center;
  width: 120px;
  margin: 20px !important;
}

.linkButton {
  border-radius: 0px;
  background-color: rgb(9, 83, 153);
  color: #fff;
  font-size: 0.875rem;
  line-height: 1.75;
  font-weight: 600;
  text-transform: uppercase;
  padding: 6px 16px;
  min-width: 64px;
  box-sizing: border-box;
  line-height: 1.75;
  margin-right: 10px !important;
  padding-top: 8px !important;
}

.linkButtonHeader {
  background-position: center;
  background-size: contain;
  margin-top: 10;
  margin-bottom: 10;
  margin-right: 0;
  margin-left: 0;
  height: 100%;
  font-size: 0.9rem !important;
  text-shadow: 1px 1px 1px #444;
}

.linkButtonHeaderCenter{
  background-position: center;
  background-size: contain;
  margin-top: 10;
  margin-bottom: 10;
  margin-right: 0;
  margin-left: 0;
  height: 100%;
  font-size: 0.9rem !important;
  text-shadow: 1px 1px 1px #444;
}

.linkButtonHeaderCenter:hover{
  color: var(--hoverColor) !important;
}

.linkButtonHamburgerMenu {
  background-position: center;
  background-size: contain;
  margin-top: 10;
  margin-bottom: 10;
  margin-right: 0;
  margin-left: 0;
  height: 100%;
  font-size: 0.9rem !important;
  text-shadow: 1px 1px 1px #444;
}

.linkButtonHamburgerMenu:hover {
  background-color: var(--hoverColorDark) !important;
}

.anchorButton{
  border-radius: 0px;
  border: none;
  background-color: #305f8a;
  cursor: pointer;
  padding: 2px 16px 0px 8px;
  color: #dddde3;
  text-shadow: 1px 1px 1px #333;
  display: flex;
  border-left: 8px solid #ffffff40;
  width: 100%;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 4px !important;
  padding-right: 12px !important;
  justify-content: center;
  max-width: 120px;
  min-width: 100px;
  min-height: 40px;
}

.anchorButton.light{
  background-color: #7f8b96;
}

.anchorButton.incident{
  background-color: #8a4a4a  !important
}

.anchorButton.leakSurvey{
  background-color: #2e91b3  !important
}

.anchorButton.siteSafe{
  background-color: #bb712d !important;
}

.anchorButton.gpsSurvey{
  background-color: #493b63 !important
}

.anchorButton.fullWidth{
  max-width: 100%;
}
.anchorButton.fullWidth.leak{
  background: #2e91b3 ;
}
.anchorButton.fullWidth:focus{
  outline: 4px solid #00f7c4;
}

.anchorButton.highlight{
  outline: 4px solid #00f7c4;
}

.surveyBlock{
  background: #434343;
  margin-left: 0px !important;
}

.surveyCountBlock{
  background: #16548e;
  margin-left: 0px !important;
}

.closedCountBlock{
  background: #a9a9a9 !important;
  color: #ffffff !important;
  text-shadow: 0px 0px 1px #1f1f1f !important;
  font-weight: 500 !important;
}

.dirtScoreBlock{
  background: #757575;
  height: 75px;
  width: 78px;
  margin: 0px 0px 0px 0px;
  box-shadow: 1px 1px 1px 0px #b3b3b3;
  color: #ffffff;
  text-align: center;
  font-weight: 600;
  text-shadow: 0px 0px 3px #000000;
  font-size: 20px;
}

.openCountBlock{
  background: #16548e;
}

.closedCountBlock{
  background: #434343;
}

.incidentCountBlock{
  background: #8a4a4a ; 
}

.leakCountBlock{
  background: #2e91b3 ;
}

.emergencyCountBlock{
  background: #fc7b03 ;
}

.aocCountBlock{
  background: rgb(50, 117, 185);
}

.pipelineCountBlock{
  background: #35924b;
}

.inaccessibleCountBlock{
  background: #2e91b3 ;
}

.meterCountBlock{
  background: #73b32e ;
}

.insideMeterCountBlock{
  background: #53498a;
}


.safetyCountBlock{
  background: #bb712d ;
}

.filterCountBlock{
  background: #587088;
}

.gpsCountBlock{
  background: #493b63  ;
}

.anchorButton.padded{
  padding: 6px !important;
  text-align: center;
  flex-direction: column;
  line-height: 19px;
}

.anchorButton.padded.small{
  min-height: 34px !important;
  min-width: 40px !important;
  max-width: 90px !important;
  font-size: 14px !important;
  line-height: 14px !important;
}

.anchorButton.padded.short{
  min-height: 34px !important;
  min-width: 40px !important;
  font-size: 14px !important;
  line-height: 14px !important;
}

.anchorButton:hover{
  color: #ffffff;
  text-decoration: none;
  text-shadow: 1px 1px 1px #000;
  border-color: #00000061;
}

.container-fluid {
  padding: 0px !important;
}

.Header{
  border-bottom: 1px solid #ffffff;
  background-color: #efeff6;
  height: 80px;
  box-shadow: inset 0px -1px 0px 0px #c5c5c5;
}

/* .headerRow {
  border-bottom: 2px solid #adadad;
  background-color: #efeff0;
} */

.contentMain {
  background: #bebebe;
  overflow: auto;
}

.logoImg {
  height: "120px";
}

.navOptionHeader{
  font-size: 14px !important;
  font-weight: normal;
  background: #efeff0 !important;
  border-bottom: 2px solid #ffffff;
  box-shadow: 0px 1px 0px 0px #a0a0a0 !important;
  text-shadow: 0px 0px 1px #6b6b6b ;
  color: #4a5156;
  top: 30px;
  max-height: 104px;
}

.navStyle {
  right: -4px;
  position: absolute;
  display: flex;
  height: 89%;
  width: 40%;
  max-width: 400px;
  min-width: 500px;
  padding: 10px 2px 2px 0px;
}

.mainContentCenter {
  /* background-color: rgb(233, 233, 239); */
  min-height: 90vh;
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.15);
}
.table-bordered thead td, .table-bordered thead th {
  border-bottom-width: 1px;
  border-color: #ffffff;
  box-shadow: 0px 1px 1px 1px #b5b5b5;
  background-color: #e9e9ef;
}

.registerMessage{
  padding-top: 20px;
  padding-bottom: 10px;
}

.spinner {
  margin: "10px";
}

.authSpinnerDiv {
  border: 1px solid #ffffff;
  margin-bottom: 10px;
  text-transform: uppercase;
  background: #efeff6;
  text-shadow: 1px 1px 1px #efefef;
  box-shadow: 0px 0px 1px 1px #d0d0d0;
  margin: auto;
  text-align: center !important;
  flex-direction: column;
  padding: 10px;
}

.authSpinnerDivMessage{
  font-size: 17px;
  font-weight: 600 !important;
  color: #224a6f;
  width:500px;
  padding:10px;
  padding-bottom: 0px;
}

.authSpinnerDivClean {
  font-size: 1.06rem;
  font-weight: 600;
  color: #104677;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 0px 0px 2px #c1c1c1;
  text-transform: uppercase;
}

.storeDiv {
  display: flex;
  align-content: center;
  align-items: center;
  padding: 0px;
  margin: 10px 10px 10px -10px;
  height: 60px;
}

.spinnerDiv{
  margin-top: 20px;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.spinnerDivUserPage{ 
  margin-top: 20px;
  align-items: center;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  display: flex;
}

.css-atemkt-wrapper-wrapper {
  position: relative;
  width: 300px !important;
  margin-top: 15px !important;
  height: 5px;
  overflow: hidden;
  background-color: rgba(9, 83, 153, 0.2);
  background-clip: padding-box;
}
.css-1s6rdps-wrapper-wrapper{
  width: 100% !important;
}
.css-4wqcmx-wrapper-wrapper{
  width: 100% !important;
}

.MuiAlert-message {
  display: flex;
  padding: 8px 0;
  flex-direction: row !important;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif !important;
  text-shadow: 0px 0px 1px #000;
}

.MuiAlert-root {
  border-radius: 0px !important;
  border: 2px solid #0000001c;
  min-width: 400px;
}

.MuiIconButton-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.MuiIconButton-root.Mui-disabled {
  color: grey !important;
}

.timeSheetHover:hover{
  background-color: #a4ebfd !important;
  color: #fff !important;
  text-shadow: 1px 1px 1px #616161;
}

.timeSheetElement{
  margin-bottom:15px;
  background-color : lightcyan;
}

.timeSheetColumn{
  margin:5px;
  width : 10%;
  word-wrap: break-word;
  cursor : pointer
}

.MuiIconButton-root.sideButton:hover {
  background-color: #e9e9ef !important;
  margin-left: 0px;
  width: 98%;
  color: #095399 !important;
}

.MuiFormControl-root.MuiTextField-root{
  display: none;
}

.MuiPickersToolbar-toolbar
{
  background-color: #095399 !important;
}

.MuiPickersDay-daySelected {
  background-color: #095399 !important;
}

.MuiButton-textPrimary {
  color:#095399 !important;
}

.MuiButton-textPrimary:hover {
  background-color: rgb(79 82 99 / 23%) !important;
  border-radius: 0px !important;
}

.MuiButton-contained {
  color: rgba(0, 0, 0, 0.87);
  box-shadow: none !important;
  background-color: #e0e0e0;
}


.attachDocumentButton {
  opacity : 0; 
  position : relative;
  bottom : 0px;
  height : 42px;
  width : 164px;
  right : 0px;
}

.uploadTicketAreasButton{
  opacity: 0;
  position: relative;
  bottom: 40px;
  height: 40px;
  width: 100%;
  right: 0px;
}

.requiredPhotoButton{
  width: 40px !important;
  display : inline-block !important;
  margin-left : 10px !important;
}

.attachDocumentButton:hover{
  text-decoration: underline;
}

.underline:hover{
  text-decoration: underline;
}

.hoverBold:hover{
  font-weight: bold;
}


.icon-button-root {
  height: 100%;
}

.MuiSnackbar-root {
  margin-top: -10px;
}


.MuiAlert-filledError {
  background-color: #892d27 !important;
}

.MuiAlert-filledSuccess {
  background-color: #4e8e32 !important;
}

.MuiButton-root {
  color: rgba(0, 0, 0, 0.87);
  padding: 5px 15px;
  font-size: 0.975rem;
  min-width: 44px !important;
  font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif !important;
  border-radius: 0px;
  text-transform: uppercase;
}

.MuiPaper-root{
  font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif !important;
  color: rgba(0, 0, 0, 0.87);
}

.MuiPaper-rounded {
  border-radius: 0px !important;
}
.MuiDialog-paperWidthLg {
  background-color: #e9e9e9 !important;
}

.MuiDialogTitle-root {
  padding: 14px 20px;
  background: #dddddd;
  border-bottom: 2px solid #CCC;
}

.MuiDialogActions-root {
  background: #CCC;
}

.MuiTypography-h6 {
  color: #333;
}

.MuiDialog-root{
  z-index: 999999;
}
.footer{
  /* background-color: var(--officialDarkBlue); */
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 10px;
  padding-right: 10px;
  width: '100%';
}

.footerSection{
  width: 220px;
}

.footerLink{
  color:var(--officialLightGray);
}

.footerLink:hover{
  color:var(--hoverColor);
}

.footerHr{
  height:2px;
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
  border-width:3px;
  border-radius: 3px;
  border-color: var(--officialLightGray);
  color: var(--officialLightGray);
  background-color: var(--officialLightGray)
}

.footerLinks{
  position: absolute;
  align-content: center;
  align-items: center;
  align-self: center;
  bottom: 0px;
  right: 0%;
  width: 100%;
  text-align: center;
  padding: 5px;
  text-transform: uppercase;
  background: #095399;
}

.footerMainLabel{
  display: flex;
  font-size: 28px;
  font-family: officialFontSemiBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
  color: var(--officialLightGray);
  text-shadow: 1px 1px 1px #1f1f1f;
  line-height: 1.0;
}

.footerSubLabel{
  display: flex;
  font-size: 14px;
  font-family: officialFontSemiBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
  color: var(--officialLightGray);
  text-shadow: 1px 1px 1px #1f1f1f;
  line-height: 1.0;
  margin-top: 10px
}

.footerLabelTerms{
  display: flex;
  font-size: 14px;
  font-family: officialFontSemiBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
  color: var(--officialLightGray);
  text-shadow: 1px 1px 1px #1f1f1f;
  line-height: 1.0;
}

.footerLabelTerms:hover{
  cursor: pointer;
  color: var(--hoverColor)
}

.right{
  align-content: initial;
  position: absolute;
  right: 0px;
  margin-right: 10px;
  margin-top: 2px;
  background-color: #16548e;
  padding-left: 30px;
}

.btn{
  margin: 0px;
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-width: 0px;
  height: 40px;
  padding: 6px 8px 0px 6px;
  display: flex;
  justify-content: center;
}

.btn-small{
  min-width: 25px !important;
  margin-left: 4px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

option{
  padding:0px;
}

.btn-primary:hover {
  background-color: #1783c5 !important;
  color: #fff !important;
  text-shadow: 1px 1px 1px #616161;
}

.mapActionButton:hover {
  background-color: #1783c5 !important;
  color: #fff !important;
  text-shadow: 1px 1px 1px #616161;
}

.btn-primary.focus, .btn-primary:focus {
  box-shadow: none;
  border: 2px solid #1990ff;
}

.btn-primary:disabled{
  background-color: slateGray;
  cursor: default;
}

.carousel .control-arrow {
  background: black;
}

.controlBorderBottom{
  border-bottom: 2px solid #bdbdbd;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: rgb(228, 228, 228);
  margin: auto;
  border: 2px solid #777;
  width: 33%;
  min-width: 410px;
  margin-top: 8%;
  border-radius: 0px;
}

.close{
  color: #325462;
  font-size: 28px;
  font-weight: bold;
  top: 10px;
  position: absolute;
}
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.underlineHover{
  text-decoration: none;
}
.underlineHover:hover{
  text-decoration: underline;
}

a:hover{
  text-decoration: none;
}

.labelHeader{
  font-size: 1.1rem;
  font-weight: 600 !important;
  text-transform: uppercase;
  padding-top: 8px;
  color: #3c5873;
  padding-left: 6px;
  text-shadow: 1px 1px 1px #cacaca;
}

.labelHeader.dehighlighted{
  color: #777;
  font-size: 1.0rem;
}

.labelHeaderBar{
  font-size: 18px;
  font-weight: 600 !important;
  color: #3c5873;
  border: 1px solid #ffffff;
  padding-bottom: 0px;
  margin-bottom: 10px;
  padding-left: 0px;
  width: 100%;
  text-transform: uppercase;
  background: #dddde3;
  text-shadow: 1px 1px 1px #d4d4d4;
  padding: 6px;
  box-shadow: 0px 0px 1px 1px #bbbbbb;
  border-color: rgb(255, 255, 255) rgb(255, 255, 255) #c7c7c7;
  box-shadow: rgb(170 178 191) 0px 0px 1px 1px;
}

.labelHeaderBarSubHeader{
  font-size: 13px;
  color: #888888;
  font-weight: 400;
  padding-top: 0px;
  text-transform: initial;
  margin-left: 1px;
  text-shadow: none;
}
.labelUser{
  font-size: 13px;
  font-weight: 600 !important;
  text-transform: uppercase;
  color: #b1b1b1;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-self: center;
  margin-top: 20px;
}

.companyList{
  font-size: 13px;
  font-weight: 600 !important;
  text-transform: uppercase;
  color: #b1b1b1;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-self: center;
  margin-top: 25px;
  width: 200px;
}

.labelHeaderBarTight{
  margin-bottom: 0px !important;
}

.labelHeaderBarTight.tightSmall{
  padding: 10px;
  width: 45%;
}

.saveBarBottom{
  border: none;
  position: fixed;
  bottom: 0px;
  box-shadow: 0px -1px 1px 0px #a5a5a5;
  border-top: 1px solid #FFF;
  padding-left: 20px !important;
}

.saveBarTop{
  position: absolute;
  z-index: 1;
  justify-content: space-between;
  margin-top: -54px;
  height: 52px;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
    cursor: pointer; 
}

.uploadButton{
  opacity: 0;
  position: relative;
  bottom: -3px;
  height: 42px;
  width: 140px;
  right: 140px;
}

.detailsErrorMessage{
  /* font-size: 17px;
  font-weight: 600;
  color: #124372;
  border: 2px solid #bfbfbf;
  padding: 20px;
  margin: 30px;
  background: #dddde3; */
  font-size: 17px;
  font-weight: 600 !important;
  color: #224a6f;
  border: 1px solid #ffffff;
  padding-bottom: 0px;
  margin-bottom: 10px;
  padding-left: 0px;
  /* width: 100%; */
  text-transform: uppercase;
  background: #dddde3;
  text-shadow: 1px 1px 1px #cecece;
  padding: 20px;
  box-shadow: 0px 0px 1px 2px #d0d0d0;
  width:500px;
  /* max-width: 600px; */
  margin: auto;
  margin-top: 20px;
}

.labelSideBar{
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: #3d2d2d;
  text-shadow: 0px 0px 2px #ffffff;
  margin: 10px 0px 0px 0px;
  cursor: pointer;
}

.labelHeaderDiv{
  align-self: center;
  align-items: center;
  text-align: center;
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
}

.labelSubHeader2{
  color: #a5a5a5 !important;
  cursor: pointer;
  margin-bottom: 13px;
  font-weight: 600;
  font-size: 14px !important;
}

.labelSubHeader3{
  color: #444;
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 0.8em;
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
}

.labelSubHeader4{
  color: #6b7f92 !important;
  cursor: pointer;
  margin: 0px 0px 0px 0px !important;
  font-weight: 600;
  font-size: 22px !important;
  text-shadow: 0px 0px 2px #f5f5f5;
  background: #afbbc6e8;
  padding: 2px 3px 0px 3px;
  border-bottom: 2px solid #9aa7b399;
}

.labelSearchBar{
  color: rgb(107, 106, 106);
  font-size: 14px;
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 9px;
}

.labelLoadingBar{
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #3c5873;
  padding-top: 10px;
}

.searchInputDiv{
  margin: 0px 6px 0px 6px;
  flex: 1 1 15%;
  max-width: 400px;
  min-width: 200px;
}

.searchInput { 
  border-radius: 1px;
  font-size: 15px;
  font-weight: 500;
  color: #16548e;
  height: 40px;
  margin-top: 0px;
  border-width: 0px;
  border-bottom-width: 2px;
  border-style: solid;
  box-shadow: 0px 0px 0px 1px #dadada;
  border-color: #b3b3b3;
  padding-bottom: 1px;
}

.couponCodeInput { 
  border-radius: 1px;
  font-size: 15px;
  font-weight: 500;
  color: #16548e;
  height: 40px;
  margin-top: 0px;
  border-width: 0px;
  border-bottom-width: 2px;
  border-style: solid;
  box-shadow: 0px 0px 0px 1px #dadada;
  border-color: #b3b3b3;
  padding-bottom: 1px;
}

.searchInput.form-control:focus {
  /* margin-top: 1px; */
  /* height: 41px;
  background-color: #fff !important; */
}

.filterInput {
  border-radius: 1px;
  max-width: 130px;
  font-size: 13px;
  font-weight: 500;
  color: #7b7b7b;
  height: 41px;
  border-width: 0px;
  border-bottom-width: 2px;
  border-style: solid;
  box-shadow: 0px 0px 1px 1px #c8c8ce;
  border-color: #bdbdbd;
  padding-bottom: 2px;
  margin-right: 4px;
}

.filterInput.short {
  /* max-width: 110px;
  min-width: 80px; */
}

.userEditInput{
  margin-top: 5px;
  max-width: 470px;
  border-radius: 0px;
  border-radius: 0px;

  font-weight: normal;
  text-shadow: 1px 1px 1px #dadada;
  font-size: 1.0rem;
  height: 40px;

  border-width: 0px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: rgb(255, 255, 255) rgb(255, 255, 255) rgb(222, 222, 222);
  box-shadow: rgb(132, 132, 132) 0px 2px 2px -2px;
}

.formInputLabel{
  font-weight: 600;
  margin-top: 10px;
  color: #444;
}
.formInputDivRow{
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}

.billingAddressInputDivRow{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .billingAddressInputZipDivRightColumn{
    width: 75%;
    display: flex;
  }
  .billingAddressInputZipDivRightColumnInnerContainer{
    display: flex;
    max-width: 350px;
  }

.billingAddressInputLabelDivColumn{
    width: 25%;
    justify-content: flex-end;
    display: flex;
}

.billingAddressInputDivColumn{
    width: 75%;
    padding-right: 20px;
}

.billingAddressInputZipLabelDivColumn{
  width: 25%;
  justify-content: flex-end;
  display: flex;
}

.billingAddressInputZipDivColumn{
  width: 40%;
}

.billingAddressInputPhoneLabelDivColumn{
  width: 15%;
  justify-content: flex-end;
  display: flex;
}

.billingAddressInputPhoneDivColumn{
  width: 45%;
  /* max-width: 160px; */
  padding-right: 20px;
}

.form-control.ccInput{
  margin: 10px;
  padding: 10px;
  max-width: 330px;
}

.form-control.addressInput{
  margin: 10px;
  padding: 10px;
  max-width: 330px;
}
.form-control.cityInput{
  margin: 10px;
  padding: 10px;
  max-width: 330px;
}
.form-control.stateInput{
  margin: 10px;
  /* padding: 10px; */
  max-width: 330px;
}
.form-control.zipInput{
  margin: 10px;
  max-width: 100px;
}
.form-control.phoneInput{
  margin: 10px;
  width: 100%
}

.form-control.ccInputSmall{
  margin: 10px;
  padding: 10px;
  max-width: 80px;
}

.label{
  display: inline-block;
  /* margin-bottom: .5rem; */
  /* font-size: 0.9rem; */
  /* align-self: center; */
}

.formLabel {
  font-size: 14px;
  margin-left: 5px;
  color: #444;
  line-height: 1.75;
  font-weight: 600;
  font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif;
  float: left;
  text-align: center;
  margin: 0px;
}
.formLabel.smallLabel{
  margin-top: 10px;
  margin-bottom: 5px;
}
.formLabel.largeLabel{
  font-size: 14px !important;
  font-weight: 600;
  background: #f9f9f9eb;
  border-bottom: 2px solid #ffffff;
  box-shadow: 0px 1px 1px 0px #a0a0a0;
  padding-top: 5px;
  text-shadow: 0px 0px 1px #a2a2a2;
  color: #4a5156;
  text-transform: uppercase;
}
.formLabel.minor{
  font-size: 19px;
  text-shadow: 0px 0px 1px #949494;
  font-weight: 200;
}
.formLabel.minor2{
  font-size: 16px;
  font-weight: normal;
}
.formLabel.pop{
  cursor: pointer;
  position: relative;
  font-weight: 600;
  font-size: 14px;
  text-shadow: 1px 1px 1px #ffffff;
}

.formLabelBillingAddress {
    font-size: 14px;
    margin-left: 5px;
    color: #444;
    line-height: 1.75;
    font-weight: 600;
    font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif;
    text-align: right;
    margin: 0px;;
    align-self: center;
  }

.formLabelBillingAddressHeader{
    font-size: 16px;
    margin-left: 5px;
    color: #444;
    line-height: 1.75;
    font-weight: 600;
    font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif;
    text-align: right;
    margin: 0px;;
    align-self: center;
    text-decoration: underline;
    margin-top: 10px
  }

.buffer{
  margin-bottom:20px;
  margin-top: 20px;
}

.btn::placeholder { /* Firefox, Chrome, Opera */ 
  color:#c5c5c5;
} 

.btn:-ms-input-placeholder { /* Internet Explorer 10-11 */ 
  color:#c5c5c5;
} 

.btn::-ms-input-placeholder { /* Microsoft Edge */ 
  color:#c5c5c5; 
} 

.passwordModal{
  width:440px;
  border: 4px solid #c7c7c7ad;
  margin-top: 100px;
}

.pageBar{
  background-color: #d9d9e0;
  border-bottom: 1px solid #e8e8e8;
  height: 51px;
  box-shadow: 0px 1px 1px 0px #989898;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 2px;
}

.pageBar.nowrap{
  flex-wrap: nowrap;
  text-align: center;
}

.pageBarSub{
  margin: 0px 0px 0px 0px !important;
  box-shadow: 1px 1px 1px 0px #b5b5b5;
}

.barHeaderDiv{
  display: flex;
  align-items: center;
  padding: 0px 10px 0px 10px !important;
  margin-left: 0px !important;
  height: 48px;
  text-shadow: 0px 0px 0px #d9d9e0;
  color: #6d6d6d;
}

.barHeaderDiv.selected{
  text-shadow: 0px 0px 1px #a2a2a2;
  color: #095399;
  background: #e9e9ef;
  border: 1px solid #bdbdbd;
  border-bottom: 3px solid #095399;
  box-shadow: 0px 0px 1px 1px #ffffff;
}

.barHeaderDiv:hover{
  color: #095399;
}

.userCheckboxRow{
  margin-left : 10px;
  cursor : pointer;
}

.userCheckboxRow:hover{
  background-color: #c6c6c7;
}

.row{
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.mapspin {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.mapContainerDiv{
  margin-top:2px;
}

.sideBarHorizontalContainer {
  max-width: 100% !important;
  flex: 0 0 100%;
  max-height: 140px;
  border-bottom: 4px solid #e9e9ef !important;; 
  box-shadow: 0px 2px 0px #d0d0d0;
  margin-bottom: 2px !important;
}

.sideBarDivHorizontal{
  background-color: #e9e9ef;
  flex-direction: row !important;
  justify-content: space-around;
}

.sideBarContainer{
  border-right: 2px solid #b9b7b7;
}

.col-2 {
  -ms-flex: 1 1 13%;
  flex: 1 1 10%;
  min-width: 210px;
  max-width: 280px;
}
.col-sm-12{
  padding: 0px;
}
.col-sm-12.col-md-6{
  /* display: none; */
}
.col-3 {
  -ms-flex: 1 1 15%;
  flex: 1 1 15%;
  max-width: 220px;
}

/* .col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  min-width: 360px;
} */

.col-5 {
  min-width: 400px;
}
.col-6{
  padding:0px !important;
}
.col-10 {
  -ms-flex: 1 1 85%;
  flex: 1 1 85%;
  max-width: 100%;
}


.iconButton{
  width: 100%;
  align-content: center;
  color: #3c5873;
  border-radius: 0px !important;
  align-self: center;
  display: flex !important;
  flex-direction: column;
  padding-top: 24px !important;
}

.iconButton:hover {
  color: #095399;
  text-decoration: none;
}

.trialButton{
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  margin: 0px !important;
  padding-bottom: 8px;
}

.sideBarDiv{
  min-width: 150px;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-bottom: 1px solid #cecece;
  background-color: #d9d9e0;
  align-items: center;
  box-shadow: inset -2px 0 2px -1px #949494;
  justify-content: flex-start;
}

.sideBarIcon{
  width: 100%;
  cursor: pointer;
  height: 100%;
  max-height: 98px;
}

.MuiSvgIcon-root.sideButton{
  transform: scale(1.7);
}

.table td, .table th {
  padding: .4rem;
  vertical-align: middle;
  border-top: none !important;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
}

.table {
  margin-bottom: 0px;
  cursor: pointer;
  border: 4px solid #cecece !important;
  box-shadow: 0px 0px 1px 1px #c1c1c1;
}

.tableBody{
  border: 2px solid #ffffff;
  box-shadow: 0px 0px 2px 0px #868686;
}
.table tbody+tbody {
  border-top: none;
}

.dropdown-toggle {
  white-space: nowrap;
  height: 40px;
  font-size: 0.85rem;
  padding: 10px;
}
.dropdown{
  display: flex;
  margin-right: 20px;
}

.searchRowBreak{
  margin:10px;
}

.searchResultsDiv{
  justify-content: center;
  align-content: center;
  overflow-y: scroll;
  margin-top:2px;
  display: flex;
  width: 100%;
}

.searchResultsDivMultiMain{
  justify-content: center;
  align-content: center;
  overflow-y: scroll;
  margin-top: 2px;
  padding: 10px;
}

.searchResultsDivMulti{
  display:flex;
  flex-direction: column;
}

.searchResultsCountDiv{
  border: 2px solid #333333;
  padding: 10px;
}

.searchResultHeader{
  display: flex;
}

.searchResultHeaderCountBlock{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2px;
  height: 43px;
  width: 46px;
  text-align: center;
}

.licenseCount{
  margin-left: -8px;
  padding: 5px 10px 0px 10px;
  color: #676767;
  font-weight: 600;
  font-size: 15px;
  background: #d9d9e0;
}
.licenseCountBlock{
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 2px solid #ffffff47;
  padding: 4px 8px 0px 8px !important;
  margin-bottom: 0px;
  background: #2e91b3;
  text-shadow: 0px 0px 2px #000;
}
.countBlock{ 
  margin: 0px 0px 0px 0px;
  box-shadow: 1px 1px 1px 0px #b3b3b3;
  color: #efeff6;
  text-align: center;
  font-weight: 600;
  text-shadow: 0px 0px 3px #1f1f1f;
  font-size: 17px;
  padding: 0px 1px 0px 0px;
  min-width: 42px;
  min-height: 30px;
  cursor: pointer;
}
.countBlock:hover{
  border-bottom: 5px solid #0095ff;
}

.countBlockDisabled{
  box-shadow: inset 1px 1px 1px 1px #7d7d7d;
  background: #aba4a4;
  color: #e0e0e0;
  text-shadow: 0px 0px 1px #000;
}

.labelHeaderSm{
  font-size: 10px;
  color: #676767;
  font-weight: 600;
  margin-top: -5px;
  padding-top: 2px;
  text-transform: uppercase;
  border-top: 4px solid #ffffff78;
  width: 40px !important;
  margin-bottom: 0px;
  text-shadow: 0px 0px 1px #b5b5b5;
}

.width50{
  width: 50px !important;
}

.width48{
  width: 48px !important;
}

.userResultsDiv{
  overflow-y: scroll;
  margin-top: 2px;
}

.searchResultsColumn{
  margin: 0px;
  flex: auto;
  margin-top: 45px;
}

.searchResultsColumnMulti{
    padding: 2px;
    border: 1px solid #a5a5a5;
    box-shadow: 0px 0px 1px 1px #cecece;
}


.formsTableHeader:hover{
  background: #446787;
}
.formsTableHeader{
  font-size: 17px;
  font-weight: 600;
  text-transform: uppercase;
  background: #73879a;
  padding: 7px 0px 9px 0px;
  width: 100%;
  color: #ffffff;
  text-shadow: 1px 1px 1px #000000;
  box-shadow: 0px 2px 1px 0px #3b3b3b;
  cursor: pointer;
}

.searchResultsTableHeader{
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  background: #9aa0a7;
  padding: 6px;
  padding-left: 10px;
  width: 100%;
  color: #FFF;
  text-shadow: 0px 0px 1px #000000;
  margin-top: 17px;
  margin-bottom: 5px;
  border-left: 14px solid;
  box-shadow: 1px 1px 1px 0px #616161;
  cursor: pointer;
}

.searchResultsTableHeader:hover{
  background: #587088;
}

.searchTableFilterInput{
  margin-top: 22px;
  margin-right: 44px;
  width: 200px;
}

.formFilterDiv{
  position: absolute;
  top: 55px;
  left: 12px;
  z-index: 9999;
  box-shadow: 2px 2px 2px 0px #656565;
  border: 3px solid #d2d2d2;
  padding: 2px;
  background: #efeff6;
  display: flex;
  flex-direction: column;
}

.formFilterDivInside{
  overflow-y: auto;
  overflow-x: hidden;
}

.formFilterContainer{
  padding: 1px;
}

.formFilterRow{
  justify-content: space-between;
}

.formFilterCountBlock{
  margin-bottom: 1px;
}

.formFilterFormName{
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  margin-left: 0px;
  margin-right: 1px;
  padding-left: 5px;
  padding-top: 3px;
  margin-bottom: 1px;
  /* height: 29px; */
  box-shadow: 0px 0px 1px #e2e2e2;
  background: #efeff6;
  color: #404040;
  text-shadow: 0px 0px 1px #e4e4e4;
  border: 1px solid #FFF;
  justify-content: flex-start;
  text-align: start;
  width: 280px;
  text-overflow: ellipsis;
  cursor: pointer;
}

.formFilterFormName:hover{
  background: #16548e;
  color: #FFF;
  text-shadow: 0px 0px 1px #000000;
  border: 1px solid #16548e;
}

.dataGroupFilterFormName{
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  margin-left: 0px;
  margin-right: 1px;
  padding-left: 5px;
  padding-top: 3px;
  margin-bottom: 1px;
  /* height: 29px; */
  box-shadow: 0px 0px 1px #e2e2e2;
  background: #e9e9ef;
  text-shadow: 0px 0px 1px #e4e4e4;
  justify-content: flex-start;
  text-align: start;
  width: 280px;
  text-overflow: ellipsis;
  cursor: pointer;
}

.dataGroupFilterFormName:hover{
  background : #16548e;
  color : #FFF;
  text-shadow: 0px 0px 1px #000000;
  border: 1px solid #16548e;
}

.formFilterButtonsDiv{
  display : inline;
  cursor : pointer;
}

.formFilterButton{
  display: inline;
  cursor: pointer;
  width: 46px;
  padding: 0px 0px 0px 0px;
  font-weight: 600;
  text-shadow: 0px 0px 2px #000;
  margin-right: 0px;
  height: 30px;
  font-size: 11px;
  text-transform: uppercase;
}

.file-field{
  border: 2px solid #d0d0d0;
  padding: 4px;
}

.divBorderBase{
  border: 2px solid #FFF;
  box-shadow: rgb(160, 160, 160) 0px 0px 2px 1px;
  padding: 10px;
  margin: 10px;
}

.container-fluid{
  width: 100%;
  min-height: 100vh; 
}

.lineAfter:after{
  content:'';
  display: block;
  border: 1px solid #E1E1E1;
  width:15%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.barLoaderPortal{
  margin: 10px;
  align-self: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

.barLoaderPortalBar{
  height: 6px;
  margin-top: 1px;
  width: 100%;
}

.accountDiv{
  display: flex;
  justify-content: center;
  padding: 20px;
  overflow-y: scroll;
  margin-top:2px;
}

.accountFormTriggerGroup{
  box-shadow: 0px 0px 1px 1px #a0a0a0;
  margin: 5px;
  display: flex;
  flex-direction: column;
}

.dashboardFormTriggerGroup{
  margin: 5px;
  flex-direction: column;
  border: 2px solid #727272;
  margin-bottom: 20px;
}

.accountFormTriggerGroupHeader{
  padding: 0px;
  border: 1px solid #ffffff;
  border-bottom: 1px solid #b7b7b7;
  display: flex;
  justify-content: space-between;
  background: #d9d9e0;
}

.accountFormTriggerGroupTriggers{
  border-bottom: 1px solid #d9d9e0;
  box-shadow: 0px 1px 1px #ffffff;
  margin-bottom: 1px;
  padding: 4px;
  background: #e9e9ef;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.formTriggerGroupTriggerValues{
  display: flex;
  margin-left: 8px;
  flex-wrap: wrap;
  flex: 1 1 80%;
  margin-bottom: 2px;
}

.formTriggerGroupTriggerControls{
  display: flex;
  width: 120px;
  flex-wrap: wrap;
  margin-left: 8px;
}

.formToArcGisLayerMapGroup{
  box-shadow: 0px 0px 1px 1px #a0a0a0;
  margin: 5px;
  display: flex;
  flex-direction: column;
}

.formToArcGisLayerMapGroupHeader{
  padding: 0px;
  border: 1px solid #ffffff;
  border-bottom: 1px solid #b7b7b7;
  display: flex;
  justify-content: space-between;
  background: #d9d9e0;
}

.formToArcGisLayerMapGroupTriggers{
  border-bottom: 1px solid #d9d9e0;
  box-shadow: 0px 1px 1px #ffffff;
  margin-bottom: 1px;
  padding: 4px;
  background: #e9e9ef;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.barLabelCenter {
  margin: auto;
  margin-left: 20px;
  padding-right: 100px;
  color: #095399;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 14px;
}

.userSearchDivButtons{
  padding: 5px 0px 5px 8px;
  box-shadow: 0px 1px 1px #b3b3b3;
  border-bottom: 1px solid #ececec;
  margin-top: 1px;
  background: #d9d9e0;
  display: flex;
}

.surveySearchDivButtons{
  padding: 5px 0px 5px 8px;
  box-shadow: 0px 1px 1px #b3b3b3;
  width: 100%;
  border-bottom: 1px solid #ececec;
  margin-top: 1px;
  background: #d9d9e0;
  display: flex;
  z-index: 1;
}

.userSearchStrip{
  display: flex;
}

.surveyStrip{
  width:100%;
}

.searchDivButtons{
  margin-top: 5px;
  margin-left: 4px;
  display: flex;
  /* width: 550px; */
}

.labelDetailName{
  font-weight: 600;
  margin-right: 4px;
  padding: 2px;
  font-size: 1.0rem;
  margin: 4px;
  color: #646c73;
  min-width: 180px;
  max-width: 400px;
}

.labelDetailTag{
  font-size: 1.0rem;
  color: #646c73;
  display:table-cell;
  vertical-align:middle; 
}

.customFormPointCommentFormDetailText{
  font-weight: 400;
  margin-right: 4px;
  padding: 2px;
  font-size: 1.0rem;
  margin: 4px;
  color: #646c73;
}

.supportDetail{
  font-weight: 700;
  color: #596473;
}

.labelDetailName.date{
  margin-top:10px;
}
.showPasswords{
  font-weight: 600;
  margin-right: 4px;
  padding: 2px;
  font-size: 14px;
  margin: 4px;
  min-width: 180px;
  max-width: 400px;
  color : #798eaf;
  cursor : pointer;
}

.showPasswords:hover{
  text-decoration: underline;
}

.profileDiv{
  border-radius: 0px;
  border: 2px solid rgb(255 255 255);
  box-shadow: 1px 1px 1px 1px #9a9a9a;
  background-color: rgb(239 239 246);
  position: absolute;
  z-index: 1000;
  right: 20px;
  top: 62px;
}

.profileDivUl{
  padding: 0;
  margin: 0;
  display: block;
  list-style-type: disc;
  margin-block-start: 0em;
  margin-block-end: 0.5em;
  padding-inline-start: 10px;
}

.profileDivLi{
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 43px;
}

.profileDivLabel{
  border-bottom: 2px solid #dedede;
  color: #434d56;
  background: #d9d9e0;
  font-weight: 600 !important;
  padding: 8px;
  padding-top: 9px;
  font-size: 13px;
  box-shadow: 1px 1px 1px 0px #c1c1c1;
  width: 180px;
  cursor: pointer;
}

.profileDivLabel:hover{
  background-color: #b4b7b9;
}

.labelHeaderName{
  font-weight: 600;
  margin-right: 20px;
  padding: 2px;
  margin: 4px;
  text-shadow: 1px 1px 1px #e8e8e8;
  color: #3c5873;
}

.filterHeaderName{
  font-weight: 600;
  margin-right: 4px;
  padding: 0px 0px 0px 4px;
  font-size: 17px;
  margin: 2px 0px 2px 0px !important;
  text-shadow: 1px 1px 1px #ffffff;
  color: #3c5873;
  background: #d9d9e0;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #c5c5c5;
}

.labelDetailHeader{
  font-size: 15px !important;
  font-weight: 600 !important;
  color: #555;
  background: #c1c1c1;
  text-shadow: 1px 1px 1px #c1c1c1;
  border: 2px solid #c1c1c1;
  padding: 15px;
  margin: 0px;
  margin-top: -28px;
}

.labelTableHeader {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #272727;
  text-shadow: 1px 1px 1px #c1c1c1;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.labelTableHeaderSmall {
  width:100px;
}

.labelTopResults{
  position: relative;
  width: 250px;
  font-size: 11px;
  font-weight: 600;
  color: #87878b;
  text-transform: uppercase;
}

.detailMain{
  overflow-y: scroll;
  margin-top: 10px;
}

.detailContent{
    /* margin: 30px; */
    /* margin-top: 30px; */
    display: block;
    margin: 1px auto;
    /* width: auto; */
    width: 0px;
    min-width: 93%;
}

.detailContentPanel{
  border: 1px solid #ffffff;
  margin-bottom: 10px;
  width: 100%;
  background: #efeff6;
  text-shadow: 1px 1px 1px #fbfbfb;
  padding: 7px;
  box-shadow: 0px 0px 1px 1px #bbbbbb;
  color: #095399;
  font-weight: 500;
}

.supportDetailContentPanel{
  border: 1px solid #ffffff;
  width: 100%;
  text-shadow: 1px 1px 1px #fbfbfb;
  padding: 7px;
  box-shadow: 0px 0px 1px 1px #bbbbbb;
  color: #095399;
  font-weight: 500;
  margin-top : 30px;
  background-color : #dddde3;
}

.carouselRow{
  justify-content: center;
  align-content: center;
  flex: 1;
  align-content: center;
  flex-direction: column-reverse;
}

.carouselGalleryDiv{
  width:100%;
}

.carousel .slider{
  min-height: 175px;
  right: 40vw;
}
.carousel .slide{
  min-width: 10vw;
}

.popupCloseIcon{
  cursor: pointer;
  position: absolute;
  display: block;
  height: 23px;
  right: 0px;
  font-size: 26px;
  margin: 0px 5px 5px 5px;
  background: #095399;
  color: #fff;
  border: 2px solid rgb(62, 62, 62);
  display: none;
}

.darkGreyBorder{
  border: 2px solid rgb(255, 255, 255);
  box-shadow: 0px 0px 1px 1px #a7a7a7;
}

.popup-content{
  background: rgb(0 0 0 / 62%) !important;
  margin: auto !important;
  border: 5px solid #00000073 !important;
  box-shadow: 0px 0px 2px 4px #5f5f5f2e;
  padding: 10px !important;
  height: 100%;
  width: fit-content !important;
}

.tax-cert-popup{
  width : 100%;
  position : relative;
  background : rgb(0 0 0 / 62%) !important;
  border : 5px solid #00000073 !important;
  box-shadow: 0px 0px 2px 4px #5f5f5f2e;
  padding : 10px !important;
  height : 100%;
}

.popup-overlay{
  overflow-y: auto;
}

.popup-horizontal{
  width: 75% !important;
}

.popupPic{
  height: 100%;
  width: auto;
}

.image-gallery-slide {
  background: #d9d9e0 !important;
  border: 3px solid #ffffff;
}

.image-gallery-slide img{
  height: 50vh;
  width: auto !important;
  margin: auto;
  display: block;
  max-width : 45vw;
}

.fullscreen .image-gallery-slide img{
  height: 85vh;
}

.image-gallery-slide-horizontal img{
  height: 50vh;
  width: auto !important;
} 

.image-gallery-slides {
  box-shadow: 0px 0px 3px 1px #656565;
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  min-width: 40px;
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
  max-width: 640px;
  display: flex;
}

.image-galleryDeleteButtonDiv{
  position: relative;
  top: 43px;
  display: flex;
  right: 0px;
  align-content: end;
  justify-content: flex-end;
}

.video-galleryDeleteButtonDiv{
  position: relative;
  display: flex;
  right: 0px;
  align-content: end;
  justify-content: flex-end;
}

.site-right-row{
  position: relative;
  left: 10px;
}

.image-gallery-thumbnails {
  overflow: hidden;
  padding: 15px !important;
}

.image-gallery-thumbnails-wrapper {
  position: relative;
  border: 3px solid #fff;
  box-shadow: 0px 0px 3px 1px #696969;
  background: #d9d9e0;
}

.image-gallery-thumbnail.active {
  border: 4px solid #08cbff;
}

.galleryPopup{
  height: 80%;
  background-color: #8e8e8e;
}

.contentPageMessage{
  /* margin: 10px 40px 0px 40px !important; */
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  background: #6ca074;
  padding: 10px;
  border: 1px solid #4a6d4fad;
  box-shadow: 0px 0px 1px 1px #a9a9a9;
  text-shadow: 0px 0px 2px #000000;
}

.contentPageMessage.warning{
  background: #57738e !important;
  box-shadow: none;
  border: 2px solid #38465461;
  box-shadow: 1px 1px 2px 0px #969696;
}

.box{
  padding: 70px 60px 35px 60px;
  background: rgba(255,255,255,0.8);
  z-index: 1;
}

.box-text{
  display: block;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 14px;
  text-transform: uppercase;
}

.box-title{
  font-size: 38px;
  /* font-family: officialFontBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif !important; */
  color: var(--officialDarkBlue);
  text-transform: none;
  text-shadow: 1px 1px 2px #b3b3b3;
  margin-bottom: 20px;
  font-weight: 700;
}

.box-title-pricing{
  margin-left: 15px;
}

.productsPageBoxTitleAlignTextEnd{
  width: 100%;
  text-align: end;
  padding-right: 15px;
}

.box-content{
  position: relative;
  float:left;
  top: 33vh;
  max-width: 60%;
}

.greyBox{
  background-color: #cccccc;
  border: 5px solid;
  border-color: white;
}

.contactPageCenter {
  color: #5d5d5d;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 40px;
  text-shadow: 1px 1px #ffffff8a;
  text-align: center !important;
  display: flex;
  flex-flow: column;
}

.contactPageContent{
  font-size: 22px;
}

.contactPageHeader {
  color: #095399;
  font-size: 30px;
  font-weight: 700;
  padding-top: 50px;
  text-shadow: 1px 1px #ffffff8a;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.inputFileSelect{
  margin-top: 10px;
    font-size: 14px;
    width: 300px;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.esri-selection-toolbar__container{
  display : none !important;
}

.esri-sketch__section .esri-sketch__tool-section{
  display : none !important;
}

.esri-sketch__panel{
  width : 100px !important;
  overflow: hidden;
}

.esri-layer-list__item-container {
  padding: 5px 5px 5px 5px !important;
  cursor: pointer;
  box-shadow: 0px 0px 0px 2px #cecece;
  margin: 2px;
  background: #efeff6;
  border-left-width: 12px !important;
}

.esri-layer-list__item-container:hover {
  background: #d5d9de;
  box-shadow: 0px 0px 0px 2px #cbcbcc;
}

.esri-layer-list__item-title {
  color: #5f5f5f !important;
  font-size: 13px;
  text-transform: uppercase;
  align-self: center;
  padding-top: 1px;
  text-shadow: 0px 0px 1px #1f1f1f;
}

.esri-layer-list__item {
  color: #ffffff !important;
  border-bottom:none;
  margin: 2px 0 !important;
}

.esri-layer-list__item-toggle {
  padding: 0 3px;
  cursor: pointer;
  color: #ffffff !important;
  display: flex;
  margin: 2px;
  text-shadow: 0px 0px 2px #000000;
}

.esri-layer-list__item-label:focus{
 outline: none !important;
}

.esri-layer-list {
  background-color: #dfdfe3 !important;
  box-shadow: 1px 1px 1px rgb(0 0 0 / 60%) !important;
  border: 2px solid #868686b3;
}

.esri-ui-corner .esri-component.esri-widget--panel {
  width: 250px !important;
  border: 2px solid #d9d9e0;
  box-shadow: 1px 1px 1px 1px #b7b7b7 !important;
}

.esri-ui-corner .esri-component {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.60) !important;
  border: 1px solid #868686b3;
}

.esri-ui-corner {
  max-width: 100%;
  max-height: 100%;
}

.esri-widget {
  margin-left: -5px;
}

.esri-view-height-small .esri-ui-corner .esri-component.esri-widget--panel{
  max-height: 100% !important;
}

.esri-view-width-greater-than-large .esri-ui-corner .esri-basemap-gallery.esri-component {
  background: #efeff6;
  border: 2px solid #d9d9e0;
  box-shadow: 0px 0px #bebebe !important;
}

.esri-ui-top-right {
  top: 20px !important;
  right: 0px !important;
}

.assignTaskButtonBar{
  position: relative;
  top: 50px;
}

.esri-sketch{
  width: 250px;
}

.esri-sketch__panel {
  width: 214px;
}

.esri-sketch__panel {
  background: #dfdfe3 !important;
}

.esri-sketch__section {
  padding: 5px;
  width: 100px;
}

.esri-sketch__button {
  display: none !important;
}

.esri-sketch__button:hover, .esri-sketch__button:focus {
  cursor: pointer;
  outline: none;
  background-color: #1783c5 !important;
  color: #fff !important;
  text-shadow: 1px 1px 1px #616161;
}

.esri-sketch__button.esri-icon-polygon {
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  width: 80px;
  background: #16548e;
  border: 2px solid #0000001c;
  display: inherit !important;
}

.esri-sketch__tool-section {
  border-right: none !important;
}

.esri-popup__header-title {
  font-weight: bold;
}

.esri-popup__header{
  background-color: #ededed;
}

.esri-widget__table {
  border: 1px solid #d2d2d2 !important;
  margin-top: 10px;
}

.esri-sketch-header{
  display: flex;
  align-items: center;
  align-content: center;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  margin: auto;
  padding: 4px 0px 2px 0px;
  color: #6b6b6b;
  border-bottom: 1px solid #0000002b;
}
 
.esri-legend-statusFilter {
  font-size: 12px;
  text-transform: uppercase;
  margin-left: 10px;
  cursor: pointer;
  padding: 4px 6px 2px 6px;
  color: #FFF;
  border: 1px solid #3c3c3c1c;
  text-shadow: 1px 1px 1px #021b33ab;
  background-color: #bfbfbf;
}

.esri-legend-statusFilter.active{
  background: #16548e;
  color: #ffffff;
  border: 2px solid #3c3c3c45;
}

.esri-legend-statusFilter-main{
  display: flex;
  align-items: center;
  align-content: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  margin: auto;
  margin: auto;
  padding: 4px 0px 2px 0px;
  color: #3a3a3a;
}

.esri-sketch {
  background-color: #dfdfe3 !important;
}

button:focus {
  outline: 2px solid -webkit-focus-ring-color;
}

.signInRequired{
  height: 120px;
  position: absolute;
  margin-top: 40px;
  width: 100%;
  background: #ffffff5c;
  padding: 40px;
  border: 2px solid #cccccc;
  text-align: center;
}
.signInRequiredBadge{
  font-size: 30px;
  margin-bottom: 6px;
  margin-right: 6px;
  color: #bb2b2b;
}
.assignmentDiv{
  display: flex;
  padding-right: 4px;
  margin-right: 4px;
  margin-top: 10px;
  margin-left: 6px;
}

.assignmentLabel{
  font-weight: bold;
  padding-right: 10px;
  padding-top: 2px;
}

.assignmentSelect{
  width: 200px;
  padding-left: 2px;
  font-weight: 600;
  font-size: 1.0rem;
  color: #095399;
  border-width: 2px;
  border-color: #dfdfe3;
}

.filterCountBlock{
  color: #808080;
  text-shadow: 0px 0px 1px #c3c3c3;
  box-shadow: none;
  border: none;
  padding: 0px 0px 0px 0px;
  min-height: 30px;
  font-size: 20px;
  font-weight: 700;
}

.filterRecordCount{
  box-shadow: none;
  background: #d9d9e0;
  border-left: 7px solid;
  padding: 2px 1px 0px 0px;
  color: #3b5165;
  text-shadow: 0px 0px 1px #d0d0d0;
  cursor: default;
}

.filterRecordCount:hover{
  border-bottom: none;
}

.formsTableHeaderCount{
  display: inline;
  padding: 8px 20px 14px 20px;
  margin-right: 10px;
  font-size: 17px;
  font-weight: 600;
  text-transform: uppercase;
  background: #9aa0a7;
}

.searchResultsTableHeaderCount{
  display: inline;
  padding: 7px 14px 9px 14px;
  margin-right: 10px;
  margin-left: -24px;
  font-size: 17px;
}

.filterSelect{
  width: 200px;
  padding-left: 2px;
  font-weight: 600;
  font-size: 15px;
  color: #16548e;
  border-width: 2px;
  border-color: #dfdfe3;
}

.filterIcon{
  height: 20px;
  width: 20px;
  margin: -7px 0px 0px 0px;
  transform: scale(0.8);
}

.leakSurveyPopupHeader{
  text-transform: uppercase;
}

.dataTables_filter {
  position: absolute !important;
  color: #5F5F65;
  position: fixed;
  top: -65px;
  right: 0px;
}

.dataTables_filter2 {
  position: relative;
  color: #5F5F65;
  border: 1px solid #b2b2b2;
  background: aliceblue;
  box-shadow: 0px 0px 1px #333;
  width: 250px;
  left: 50%;
  margin-left: -125px;
  top: -3px;
  margin-bottom: 10px;
}

.dataTables_info{
  margin-top: 10px;
  font-size: 14px;
}

.dataTables_paginate{
  margin-top: 10px;
}
.dataTable_headerBold{
  /* border-bottom: 2px solid #727276; */
  font-weight: 500;
  padding: 10px;
  text-transform: uppercase;
  font-size: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px;
  display: block;
  overflow: hidden
}

.dataTable_rowStandard{
  font-size: 15px;
  align-self: center;
}

.dataTable_headerRow{
  width: 100%;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  background: #00599c;
  border: 2px solid #1f4889;
  color: white;
  text-shadow: 1px 1px #333;
}

.gridLayoutColored{
  /* border: 2px solid #111; */
}

.dataTable_rowHighlight{
  border: 2px solid #d9d9d9;
  height: 150px;
}
.dataTable_rowHighlight:hover{
  border: 2px solid #333;
  background: #cbdcf2 !important;
  border: 2px solid #ffffff;
  box-shadow: 0px 0px 0px 2px #00599c;
}

#a{
  color: #ffffff;
  font-size: 12px;
  text-decoration: none;
  background-color: #16548e;
  padding: 6px 8px 6px 8px;
  border: 2px solid #00000033 !important;
  /* text-shadow: 1px 1px 1px #021b33; */
}

.sorting {
  font-size: 14px !important;
  cursor: pointer;
  text-shadow: 1px 1px 1px #dedede;
}

.sorting_asc{
  font-size: 14px !important;
  cursor: pointer;
  text-decoration: underline;
  color: #16548e;
}

.sorting_desc{
  font-size: 14px !important;
  color: #16548e;
  text-decoration: overline;
  cursor: pointer;
}

.horizontalLineBuffer{
  width: 100%;
  border-top: 2px solid #AAA;
  margin: 3px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.horizontalLine{
  border-top: 2px solid #d1d1d1;
  margin: auto;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.licenseEditDivMain{
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  max-width: 40%;
}

.licenseEditDivContent{
  border: 1px solid #ffffff;
  background: #efeff6;
  padding: 10px;
  box-shadow: rgb(179, 179, 179) 0px 0px 1px 1px;
}

.licenseEditDivButtonBar{
  align-items: flex-end;
  display: flex;
  flex: auto;
  justify-content: flex-end;
}

.licenseDiv{
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.assignViewDropdown{
  z-index: 1;
  position: absolute;
  min-width: 175px;
  cursor: pointer;
  top: 60px;
  left: 8px;
  background: #dfdfe3;
  color: #4c4c4c;
  text-shadow: 0px 0px 2px #fff;
  box-shadow: 0px 0px 1px 1px #bfbfbf;
  border-width: 0px 0px 3px 0px;
  border-color: #c7c7c7;
}

.assignViewDropdownHeader{
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  height: 40px;
  font-weight: 700;
  font-size: 13px;
  margin: 2px;
  background-color: #d6d6d6;
  width: 298px;
  border: 1px solid #ffffff;
  box-shadow: 0px 0px 1px 1px #b3b3b3;
  text-shadow: 0px 0px 1px #fff;
  color: #808080;
}

.openBlock{
  float: left;
  margin-left: 4px;
  margin-top: 4px;
  padding-top: 2px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 16px;
  text-shadow: 0px 0px 2px #000000;
}

.closedBlock{
  float: right;
  margin-top: 4px;
  margin-right: 4px;
  padding-top: 3px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 16px;
}

.headerBlockSurvey{
  float: right;
  margin-top: 4px;
  margin-right: 5px;
  padding-top: 3px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 16px;
}

.openBlockText{
  display: inline-block;
  margin-left: 4px;
}

.closeBlockText{
  margin-right: 0px;
  float: right;
}

.surveyBlockText{
  float : right;
  margin-right: 5px;
}

.assignViewUserName{
  margin-left: 5px;
  display: inline-block;
  white-space: nowrap;
  max-width: 145px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: #222;
  margin-top: -1px;
  top: 5px;
  position: relative;
}

.closedAssignedBlock{
  width: 40px;
  float: right;
  margin-right: 0px;
  margin-top: 0px;
  font-size: 16px;
  padding-top: 3px;
  box-shadow: none !important;
}

.openAssignedBlock{
  float:left;
  width: 40px;
  font-size: 16px;
  padding-top: 3px;
  box-shadow: none !important;
}

.assignViewDropdownContent{
  min-height: 100px;
  max-Height: 350px;
  overflow-y: auto;
  width: 305px;
}




.assignViewOpenClosed{
  flex: 1;
  flex-direction: row;
  justify-content: stretch;
  border-bottom: 2px solid #c1c1c1;
  margin: 5px;
  margin-top: 0px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  color: #929292;
}

.assignViewHeaderText{
  display: inline-block;
  margin-top: 10px;
  margin-left: 57px;
}

.assignViewHeaderTextSurvey{
  display: inline-block;
  margin-top: 10px;
  margin-left: 15px;
}

.assignViewDropDownItem{
  color: black;
  font-weight: 600;
  margin-left: 5px;
  margin-right: 5px;
  border: 2px solid #ffffff;
  background-color: #d6d6d6;
  box-shadow: 0px 0px 0px 1px #b1b1b1;
  height: 34px;
}

.assignViewDropDownItem:hover{
  background-color: #ececec;
}

.blueBar{
  padding-left: 5px;
  padding-right:5px;
  padding-top: 2px;
  padding-bottom:2px;
  background-color: #16548e;
}

.licenseLabel{
  background: #dddde3;
  width: 90%;
  border: 2px solid #efefef;
  padding: 8px;
  font-size: 16px;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #4c4c4c;
  text-shadow: 0px 0px 2px #fff;
  box-shadow: 0px 0px 1px 1px #bfbfbf;
  border-width: 0px 0px 3px 0px;
  border-color: #c7c7c7;
}

.buttonEditUser{
  width:20%;
}

.descriptionBox{
  display: flex;
  flex-direction: column;
  background: #e3e3e8;
  border: 2px solid #d4d4d4;
  padding: 10px;
  font-size: 13px;
  margin-top: 20px;
  color: #3c3c3c;
  margin-left: 14px;
  width: 100%;
}

.editUserInputContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inputFormCenter{
  width: 400px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  align-self: center;
  margin: auto;
}

.react-datepicker {
  /* font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; */
  font-size: 0.9rem;
  background-color: #fff;
  color: #000;
  border: 2px solid #ffffff;
  box-shadow: 0px 0px 1px 2px #656565;
  border-radius: 0px;
  display: inline-block;
  position: relative;
}

.datelabeldiv{
  display: flex;
  margin-top: 10px;
}

.searchLabel{
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  color: #949494;
  display: flex;
}

.largeLightMargin{
  margin: 6px;
  transform: scale(1.2);
  color: #abbdd3;
}

.noBorder{
  border: none !important;
}

.compareLabel{
  font-size: 15px;
  color: rgb(96, 96, 96);
  margin: 10px 10px 10px 0px;
  font-weight: 600;
  padding: 10px;
  border-bottom: 2px solid #c5c5c5;
  width: 48%;
  background: #dddde3;
  text-transform: uppercase;
  text-shadow: rgb(251, 251, 251) 1px 1px 1px;
  display: flex;
  text-align: center !important;
  flex-direction: column;
}

.comparisonImgRowDiv{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.comparisonImgRowInsideDiv{
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  border: 2px solid #fff;
  box-shadow: 0px 0px 1px 1px #c5c5c5;
  max-width: 50%;
}
.comparisonImgLabelDiv{
  background: #dddde373;
  text-align: center;
  padding: 4px;
  font-size: 13px;
  text-shadow: 0px 0px 2px #FFF !important;
  font-weight: 600;
  color: #333;
}

.notificationRowControl.large{
  max-width: 240px;
  margin-left: 10px;
}

.notificationRowControl.largeEmail{
  max-width:275px;
  margin-left:10px;
}

.downloadSubscriptionControl.largeEmail{
  max-width:275px;
  margin-left:10px;
}

.dataGroupContainer{
  overflow: scroll;
  max-height: 500px;
}

.notificationRowControl.small{
  max-width: 100px;
  margin-left: 10px;
}
.notificationRowControl.xsmall{
  max-width: 20px;
  margin-left: 10px;
}
.notificationRowControl{
  max-width: 160px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
}
.notificationRowControl.trigger{
  max-width: 180px;
  min-width: 160px;
  margin-left: 0px;
  font-size: 14px;
  margin-top: 3px;
  background: aliceblue;
  color: #3c5873;
  font-weight: 600;
  margin-left: 2px;
}
.notificationRowControl.trigger.datetime{
  margin-left: 0px;
  font-size: 14px;
  height: 35px;
  margin-left: 3px;
  margin-top: 3px;
  min-width: 110px;
  border-bottom: 2px solid #00000024 !important;
}

.notificationRowButton{
  max-width: 64px !important;
  height: 35px !important;
  min-width: 58px;
  align-items: center;
  padding: 0px;
  margin: 0px 0px 0px 10px !important;
  justify-content: center;
  display: flex;
}

.notificationRowDiv{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 10px;
  background: #e9e9ef;
  border: 2px solid #efeff6;
  box-shadow: 0px 0px 1px 1px #b5b5b5;
  padding: 4px;
}

.dataGroupUL{
  list-style-type : none;
  margin : 0;
  padding : 0;
}

.parentDataGroup{
  background: #e9e9ef;
  border: 2px solid #efeff6;
  box-shadow: 0px 0px 5px 1px #b5b5b5;
  padding: 4px;
  width: 100%
}

.childDataGroup{
  margin-left : 25px;
  margin-bottom: 10px;
  border-left: 1px solid #b5b5b5;
  padding-left : 15px
}

.childDataGroupFilter{
  margin-left : 15px;
  margin-bottom: 10px;
  border-left: 1px solid #b5b5b5;
  padding-left : 5px
}

.caret::before {
  content: "\25B6";
  color: black;
  display: inline-block;
  margin-right: 6px;
}

.caret-down::before {
  -ms-transform: rotate(90deg); /* IE 9 */
  -webkit-transform: rotate(90deg); /* Safari */
  transform: rotate(90deg);  
}

.caret {
  cursor: pointer;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

.dataGroupParent{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0px;
  background: #e9e9ef;
  border-top: 2px solid #efeff6;
  border-Left: 2px solid #efeff6;
  border-right: 2px solid #efeff6;
  padding: 4px;
}

.gisExportEnableExportToLayerContainer{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  margin-top: 15px;
  margin-bottom: 5px;
}

.customOrderDiv{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.customFormRowDiv{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
}
.customFormRowDivPopup{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
  padding: 2px;
  box-shadow: 0px 0px 1px 1px #cacaca;
  background: #dddde3;
  border: 1px solid #fff;
}
.customFormRowDivPopupSub{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
  padding: 2px;
  box-shadow: 0px 0px 1px 1px #cacaca;
  background: #dddde3;
  border: 1px solid #fff;
  margin-left : 15px !important;
}
.customFormRowDivCustomValue{
  justify-content: center;
  width: 100%;
  margin-bottom: 4px;
}
.customFormRowButton{
  max-width: 64px !important;
  min-width: 58px;
  align-items: center;
  padding: 0px;
  margin: 0px 0px 0px 10px !important;
  justify-content: center;
  display: flex;
}
.customFormValueDropDiv{
  border: 2px solid #ffffff;
  box-shadow: 1px 1px 1px 1px #a5a5a5;
  margin: auto;
  padding: 4px;
  margin-bottom: 10px;
}
.customFormCheckbox{
  align-self: center;
  cursor: pointer;
  position: relative;
  bottom: 0px;
  margin-left: -3px;
  margin-top: 13px;
  margin-bottom: 15px;
  width: 21px;
  height: 21px;
}
.customFormGroupAssignment{
  margin-top: 1px;
  background-color: aliceblue;
  border-bottom: 2px solid #b3b3b3;
  margin-left: -10px;
  font-size: 14px;
  font-weight: 600;
  color: #3e5b73;
  max-height: 150px;
  overflow-y: auto;
  max-width: 300px;
}
.customFormSelectBox{
  font-size: 13px;
  max-width: 200px;
  overflow-y: auto;
  max-height: 150px;
  color: #333;
}

.MuiCheckbox-colorPrimary.Mui-checked:hover{
  background-color: transparent !important;
  padding: 5!important;
  width: 24px !important;
  margin-left: 9px !important;
  margin-right: 9px !important;
}

.MuiCheckbox-colorPrimary:hover{
  background-color: transparent !important;
  padding: 5!important;
  width: 24px !important;
  margin-left: 9px !important;
  margin-right: 9px !important;
}

.customFormControlGroup{
  display: flex;
  margin-right: 6px;
}

.customFormSectionDelimiter{
  font-size: 15px;
  font-weight: 600 !important;
  color: #224a6f;
  border: 1px solid #ffffff;
  padding-bottom: 0px;
  margin-bottom: 10px;
  padding-left: 0px;
  width: 100%;
  text-transform: uppercase;
  background: #dddde3;
  text-shadow: 1px 1px 1px #fbfbfb;
  padding: 6px;
  box-shadow: 0px 0px 1px 1px #bbbbbb;
}
.customFieldAttr{
  display: flex;
  flex-direction: row;
  height: 36px;
}
.registrationTypeDiv{
  width: 100%;
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 20px;
  border: 2px solid #FFF;
  box-shadow: 0px 0px 1px 1px #c5c5c5;
  display: flex;
}

.registrationLabel{
  align-items: center;
  display: flex;
  margin: 0px 0px 2px 0px;
  margin-left: 0px;
  padding: 10px 10px 10px 10px;
  color: #3f576d;
  font-size: 16px;
  text-transform: capitalize;
  border-bottom: 2px solid #a6a6a6;
  background: #3333331f;
  justify-content: center;
}

.customFormLabel{
  align-items: center;
  display: flex;
  margin: 0px;
  margin-left:10px;
  padding: 0px 8px 0px 0px;
  color: #3f576d;
  font-size: 14px;
  text-transform: uppercase;
}
.notificationLabel{
  align-items: center;
  display: flex;
  margin: 0px 0px 0px 0px;
  padding: 0px 8px 0px 0px;
  color: #3f576d;
  font-size: 14px;
  text-transform: uppercase;
}
.chartLabel{
  align-items: center;
  display: flex;
  margin: 0px 0px 0px 0px;
  padding: 0px 8px 0px 0px;
  color: #3f576d;
  font-size: 14px;
  margin-left: 20px;
  text-transform: uppercase;
}

.downloadSubscriptionLabel{
  align-items: center;
  display: flex;
  margin: 0px 0px 0px 0px;
  padding: 0px 8px 0px 0px;
  color: #3f576d;
  font-size: 14px;
  max-width: 145px;
  text-transform: uppercase;
}

.dataGroupLabel{
  align-items: center;
  margin: 0px 0px 0px 0px;
  padding: 3px 8px 0px 2px;
  color: #3f576d;
  font-size: 14px;
  text-transform: uppercase;
  position: relative;
  top:  5px;
}



.notificationLabel.small{
  font-size: 14px
}

.arcGisFormToLayerMapFormLabel{
  align-items: center;
  display: flex;
  margin: 0px 0px 2px 0px;
  margin-left: 0px;
  padding: 0px 10px 0px 10px;
  color: #215688;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  text-shadow: 1px 1px 1px #c7c7c7;
}

.arcGisFormToLayerMapDropdown{
  width: 200px;
  margin-left: 10px;
  font-size: 14px;
}

.notificationRowControl{
  max-width: 180px;
  margin-left: 10px;
  font-size: 14px;
}

.downloadSubscriptionControl{
  max-width: 180px;
  margin-left: 0px;
  margin-right: 10px;
  font-size: 14px;
}

.triggerFormLabel{
  align-items: center;
  display: flex;
  margin: 0px 0px 2px 0px;
  margin-left: 0px;
  padding: 0px 10px 0px 10px;
  color: #215688;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  text-shadow: 1px 1px 1px #c7c7c7;
}

.customFormLabel{
  height: 100%;
  align-items: center;
  display: flex;
  margin: 0px 0px 2px 0px;
  margin-left: 0px;
  padding: 0px 10px 0px 10px;
  color: #3f576d;
  font-size: 15px;
  text-transform: capitalize;
}

.customFormLabel.small{
  font-size: 12px;
}
.customFormLabel.large{
  font-size: 15px;
  width: 186px;
  justify-content: flex-end;
}
.customFormLabel.reminder{
  color: #999;
  text-transform: none;
}

.customFormRowControl.large{
  max-width: 240px;
}
.customFormRowControl.small{
  max-width: 100px;
  margin-left: 10px;
}
.customFormRowControl.xsmall {
  width: 18px;
  margin: 1px 0px 0px 0px;
}
.customFormRowControl.filterCheckBox{
  width: 18px;
  margin: -7px 0px 0px 0px;
  cursor: pointer;
}
.customFormRowControl{
  max-width: 170px;
  margin-right: 5px;
  background-color: #f9f9f9;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0px;
  color: #034f8e;
}
.customFormRowControl.highlighted{
  font-size: 16px;
  font-weight: 600;
  color: #4375a4;
}
.customFormRowControl.customValue{
  margin:0px;
}

.customFormRowControlCustomValueEdit{
  max-width: 170px;
  font-size: 14px;
  margin-top: 3px;
  margin-left: 2px;
  font-weight: 600;
  color: #16548e;
}
.customFieldValueControlDiv{
  display: flex;
  justify-content: space-between;
}
.customFieldValueControl{
  margin-top: 10px;
}

.notificationBold{
  font-weight: 700;
  text-transform: uppercase;
}

.formsElement{
  min-width: 200px;
}

.formsElementsmall{
  width: 120px;
  cursor: pointer;
}
.formsElementsmall.xsmall{
  min-width: 60px;
  width: 60px;
  cursor: pointer;
  margin: 0px !important;
}
.formsElementsmall.square{
  min-width: 48px;
  width: 48px;
}

.removeLayerFieldMapping{
  width: 120px;
  cursor: pointer;
}

.removeLayerFieldMapping.square{
  min-width: 35px;
  width: 35px;
  height: 35px;
  margin-left: 10px !important;
  margin-right: 10px !important;
  padding: 0px 0px 0px 0px !important;
}

.marketDivContainer{
  border: 2px solid #fff;
  background: #eae9ef;
  box-shadow: 0px 0px 1px 1px #a7a7a7;
  padding: 20px;
}

.marketHeader{
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 15px;
  color: #095399;
  text-shadow: 1px 1px 1px #e0e0e0;
}

.marketDivRow{
  font-size: 17px;
  margin: auto;
  width: 70%;
  text-align: justify;
}

.marketHeaderMain{
  font-size: 40px;
  font-weight: 600;
  color: #095399;
  text-shadow: 0px 0px 2px #777777;
  /* text-decoration: underline; */
  /* background: #ffffffb3; */
  /* margin-top: 40px; */
  text-align: center;
  /* background: #e8e8e8; */
  /* box-shadow: 0px 1px 2px 1px #989898; */
  /* width: 60%; */
  /* margin: auto; */
  padding: 10px;
  margin-top: 20px;
}
.marketHeaderMainSub{
  font-size: 20px;
  font-weight: 300;
  color: #989898;
  text-shadow: 0px 0px 2px #e0e0e0;
  text-align: center;
  /* background: #ffffffb3; */
  border-bottom: 3px solid #bfbfbf87;
  /* box-shadow: 0px 0px 2px 1px #b3b3b3; */
  width: 60%;
  margin: auto;
  padding: 12px;
  padding-bottom: 20px;
  margin-top: 0px;
}

.marketProducts{
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  margin-top:20px;
}

.marketProductItem{
  height: 490px;
  overflow: auto;
  width: 370px;
  border: 2px solid #FFF;
  box-shadow: 0px 0px 3px 2px #1f1f1f8c;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-blend-mode: screen;
  background-color: #ffffffc4;
  cursor: pointer;
}
.marketProductItem:hover{
  box-shadow: 0px 0px 2px 2px #497ea5;
  cursor: pointer;
  text-decoration: none !important;
}
.marketProductItemColumn:hover{
  cursor: pointer;
  text-decoration: none !important;
}

.marketProductItemColumn{
  display: flex;
  flex-direction: column;
}

.marketProductImage{
  border-top: 2px solid #ffffff77 !important;
  border-bottom: 2px solid #ffffff77 !important
}

.marketAnchor{
  display: flex;
  flex-direction: column;
}

.info{
  position: relative;
  transform: scale(1.5);
}

.info:before
{
    content: '?';
    display: inline-block;
    font-family: sans-serif;
    font-weight: bold;
    text-align: center;
    width: 2.4ex;
    height: 2.4ex;
    font-size: 1.4ex;
    line-height: 1.8ex;
    border-radius: 1.2ex;
    margin-right: 4px;
    padding: 1px;
    color: gray;
    background: white;
    border: 1px solid gray;
    text-decoration: none;
    cursor: pointer;
    position: relative;
}

.info:hover:before
{
    color: white;
    background: gray;
    border-color: white;
    text-decoration: none;
}

.MuiTableCell-head {
  background: gainsboro;
}

.MuiTableContainer-root {
  border: 2px solid #fff !important;
  box-shadow: 0px 0px 1px 1px #a7a7a7 !important;
}

.backgroundButton{
  background-image: url(/src/siteRightScreening60s.jpg);
  background-size: cover;
  background-color: rgb(22, 84, 142);
  background-blend-mode: soft-light;
  border: none !important;
  font-size: 15px;
}

.registrationButtonsDiv{
  font-weight: bold;
  position: relative;
  border: 2px solid #16548e73;
  position: relative;
  width: 400px;
  box-shadow: 0px 0px 0px 1px #8a8a8a;
}

.registrationStoreDiv{ 
  width: 100%;
  display: flex;
  flex-direction: column;
}

.marketingAppStoreButtonContainer{
  margin-top: 10px;
  height: 35px;
}

.marketingButtonPriceLabel{
  font-family: officialFontSemiBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif !important;
  font-size: 11px;
  color: var(--officialDarkBlue);
  text-align: center;
  height: 20px;
  width: 180px;
  margin-left: -5px 
}

.marketingAppStoreButtonAnchor{
  height: 35px;
  
}

.appStoreButtonImg{
  width: 100px;
}

.marketingContentSection{
  padding-top: 50px;
}

.icon-bar {
  width: 22px;
  height: 2px;
  background-color: #eeeef1;
  display: block;
  position: relative;
  bottom: 4px;
  transition: all 0.2s;
  margin-top: 4px
}
.navbar-toggler .top-bar {
  -ms-transform: rotate(45deg);
  -ms-transform-origin: 10% 10%;
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}

.navbar-toggler .middle-bar {
  opacity: 0;
}

.navbar-toggler .bottom-bar {
  -ms-transform: rotate(-45deg);
  -ms-transform-origin: 10% 90%;
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
}

.navbar-toggler.collapsed .top-bar {
  -ms-transform: rotate(0);
  transform: rotate(0);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
}

.navbar-toggler.collapsed .bottom-bar {
  -ms-transform: rotate(0);
  transform: rotate(0);
}

.buttonBanner{
  margin-top: 10px;
}

.bordered{
  border-width: 2px;
  box-shadow: 0px 0px 1px 1px #cecece;
  border-style: solid;
  border-color: #ffffff;
  margin-right: 5px;
}

.addedituser-container{
  overflow-y: scroll;
  height: 100%;
  align-content: center;
}
.addedituserButtonBar{
  display: flex;
  justify-content: flex-end;
}
.sideBarIcon .active {
  background-color: #e9e9ef;
  /* margin-left: 4px; */
  /* box-shadow: 0px 0px 1px 1px #ffffff; */
  margin-top: 1px;
  /* border: 2px solid #bdbdbd; */
  border-bottom: 5px solid #333;
  color: #095399;
  width: 97%;
}
/* 
Dashboard CSS 
*/
.scan-data-container {
  height: 45%;
  min-height: 300px;
  overflow-y: scroll;
  padding-bottom: 70px;
}

.net-graph-container {
  /* padding-right: 215px; */
  padding-right: 100px;
  padding-left: 100px;
}

.network-filters {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.60) !important;
  border: 1px solid #868686b3;
}

.counters-container {
  align-content: center;
  min-width: 150px;
  /* padding: 6px 15px 4px 10px !important; */
  min-height: 53px;
  margin-top: 1px;
  background: #efeff6;
  border: 1px solid rgb(255, 255, 255);
  box-shadow: rgb(185 185 185) 0px 0px 1px 1px;
}

.DatetimePicker table {
  height: 100%;
}

.DatetimePicker .rdtDays {
  height: 400px;
}

#leak-survey-tab {
  height: 100%;
}

.dashboard-grid {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.dashboard-grid::-webkit-scrollbar {
  display: none;
}

.dashboard-grid::-webkit-scrollbar {
  display: none;
}

.hide-vertical-scrollbar{
  scrollbar-width: none;
  overflow-y: scroll;
  -ms-overflow-style: none;
}
.hide-vertical-scrollbar::-webkit-scrollbar{
  display: none;
}

.sitesafe-tab{
  height: 100%;
  overflow-y: hidden;
}

.innaccessibleGaugeGraphSelect {
  height: 25px;
  background-color: #E9E9EF;
  border: none;
  width: 55%;
}

.innaccessibleGaugeGraphSelect:focus {
  border: none !important;
}

.innaccessibleGaugeGraph-graph {
  height: calc(100% - 25px);
}

#scan-data-graph {
  margin-bottom: 100px;
}

#scan-data-graph table#data-table td:nth-child(1) {
  display: none;
}

#scan-data-graph table#data-table thead th:nth-child(1){
  display: none;
}
/* Leak Survey Dashboard */
#leak-dashboard-table-con  div[data-test="datatable-entries"] {
  display: none !important;
}

#leak-dashboard-table-con .dataTables_filter{
  right: 0;
  width: 100%;
  padding-left: 5px;
}

#leak-dashboard-table-con .dataTables_filter label {
  width: 175px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.leak-dashboard-table {
  margin-top: 60px;
}

table#usergroup-user-table td:nth-child(1), table#usergroup-user-table td:nth-child(2) {
  display: none;
}

table#usergroup-user-table thead th:nth-child(1), table#usergroup-user-table thead th:nth-child(2){
  display: none;
}

table#usergroup-user-table thead th:nth-child(3) {
  width: 120px;
  max-width: 120px;
}

.usergroup-user-table-counter {
  display: flex;
  flex-direction: row;
  font-weight: 600;
  font-size: 16;
  margin-right: 30px
}

table#taxCert-table tbody tr {
  height:40px
}

table#taxCert-table thead th:nth-child(5) {
  width: 180px;
  max-width: 180px;
  min-width: 180px;
}



.taxCert-table-counter {
  display: flex;
  flex-direction: row;
  font-weight: 600;
  font-size: 16;
  margin-right: 30px
}

.dashboard-sitesafe-datatable {
  margin-top: 70px;
  /* overflow-y: scroll; */
}

#data-table {
  overflow-x: scroll;
  white-space: nowrap;
}

.filterMessage{
  padding: 6px;
  font-size: 15px;
  font-weight: 600;
  color: #16558f;
}
.toggleButton{
  height: 40px;
  border-width: medium !important;
  border-color: #bebebe !important;
  background: #cacaca;
  border-right: none !important;
  border-left: none !important;
  margin-top: 2px;
}

/* Investigation Dashboard */
#investigation-tab-container {
  overflow-y: scroll;
}

.datatable-filter-message{
  display: flex;
  align-items: center;
}

.formdata-dashboard-table{
  margin-top: 0px;
}

.formdata-dashboard-table .dataTables_filter{
  position: absolute !important;
  color: #5F5F65;
  position: fixed;
  top: -65px;
  right: 5px;
}

.investigation-dashboard-table{
  margin-top: 60px;
}

.investigation-dashboard-table div[data-test="datatable-entries"]{
  display: none !important;
}



.investigation-dashboard-table .dataTables_filter{
  right: 0;
  width: 100%;
  padding-left: 5px;
}

.videoGallery .image-gallery-slide-wrapper {
  display: none;
}

.investigation-dashboard-table .dataTables_filter label {
  width: 175px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.dataCards.entityClosedtrue{
  display: none;

}

.historyLabel{
  color : #095399;
  font-weight : bold;
  position: relative;
  bottom: 1px;
  margin-left: 5px;
}

@media(min-width: 1024px){
  .labelHeader.scale{
    display: inline-block;
  }
}

@media(max-width: 1024px){
  .fdataCards {
    display: inherit;
  }
  .mdbDataTable {
    display: none;
  }
  .dataCards.entityClosedtrue{
    display: inherit;
  }
}



@media(max-width: 1562px){
  .labelHeader.scale{
    display: none;
  }
}

@media(min-width: 825px){
  .searchResultsDiv.fullfalse{
    flex: 0 0 33%;
  }
  .searchResultsDiv.fulltrue{
    width: 100%
  }
  .entityDetails{
    /* max-width: 67%; */
  }
}

@media(max-width: 910px){
  .investigation-filter-message{
    margin-bottom: 60px;
  }
  .scan-data-container {
    height: 50vh;
  }
  #data-table {
    height: 50vh;
    overflow-y: scroll;
  }
  .searchResultsDiv.fullfalse{
    display: none;
  }
  .searchResultsDiv.fulltrue{
    display: flex;
  }
  .dataCards.entityClosedfalse{
    display: none;
  }
  .labelHeader.scale{
    display: block;
  }
}

.lineMarkerDiv{
  margin-left: 186px;
  cursor: pointer;
}

@media(max-width: 519px){
  .detailContent{
    margin-top: 45px;
  }
}

@media(max-width: 1512px){
  .assignTaskButtonBar{
    top: 65px;
  }
  .searchDivButtons{
    position: relative;
    background: #d9d9e0;
    padding-bottom: 4px;
    padding-top: 3px;
    border-bottom: 1px solid #a2a2a2;
    box-shadow: 0px 0px 1px 0px #ffffff;
    width: 100%;
    margin-top: 5px;
    padding-left: 12px;
    margin-left: 0px;
    z-index: 1;
  }
  .assignViewDropdown{
    margin-top: 50px;
  }
  .esri-ui-top-right {
    top: 35px !important;
  }
}

@media (max-width: 1199px){
  
  
  .searchResultsDiv{
    margin-top: 50px;
  }
  .entityDetails{
    margin-top: 50px;
  }
  .spinnerDivUserPage{ 
    margin-top: 60px;
  }
  .image-gallery-thumbnails .image-gallery-thumbnails-container {
    max-width: 380px;
  }
}
@media (min-width: 911px) and (max-width: 1059px) {
  .pageBar{
    width: 66vw;
    margin-left: 6px;
  }
}

@media(max-width: 1059px){
  .detailMain{
    margin-top: 55px !important;
  }
}

@media(max-width: 1399px){
  .pageBar{
    margin-left: 2px;
  }
  .usergroup-counters {
    justify-content: center;
    margin-left: 0px !important;
  }
  .bs-select{

  }
  .sitesafe-tab{
    height: 100%;
    overflow-y: scroll;
  }
  #scan-data-graph {
    margin-bottom: 150px;
  }
  .sideBarIcon{
    max-height: 40px;
  }
  .MuiIconButton-root.iconButton{
    padding: 6px 6px 6px 6px !important;
  }
  .MuiSvgIcon-root.sideButton{
    transform: scale(1.2);
  }
  .MuiIconButton-label{
    flex-direction: row;
    overflow: hidden;
    flex-wrap: wrap;
  }
  .labelSideBar{
    font-size: 13px;
    margin: 0px 0px 0px 4px;
  }
}

.groupAssignmentUserList{
  margin-top: 10px;
  background-color: white;
  max-height: 450px;
  overflow-y: auto;
  border-width: 0px;
  border-bottom-width: 2px;
  border-style: solid;
  box-shadow: 0px 0px 1px 1px #c8c8ce;
  border-color: #bdbdbd;
  padding-bottom: 2px;
  font-size: 14px;
}

.bs-select{
  /* margin-top: -78px; */
  margin-left: -1px;
  padding: 8px 8px 1px 8px;
  color: #676767;
  font-weight: 600;
  font-size: 13px;
}

.page-item.disabled .page-link {
  border-color: #aab2bf;
  border-radius: 0 !important;
  box-shadow: 0px 0px 1px 1px #fff;
  background-color: #d9d9e0;
}

.page-link {
  cursor: pointer;
  border-radius: 0 !important;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9e9ef;
  border-color: #aab2bf;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #16548e;
  border-color: #007bff;
  font-weight: 500;
  box-shadow: 0px 0px 1px 1px #2f2f2f;
}

.dataTables_info {
  margin-top: 10px;
  font-size: 14px;
  color: #909090;
  font-weight: 600;
  font-size: 16px;
  text-shadow: 0px 0px 2px #fff;
}

.dataTable_staticHeader{
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 18px;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 12px;
  height: 12px;
  border-right: 2px solid rgb(181 181 181);
  border-bottom: 2px solid rgb(181 181 181);
}

.react-grid-item.cssTransforms {
  transition-property: transform;
  border: 3px solid #efeff6;
  box-shadow: 0px 0px 1px 1px #8a8a8a;
  border-radius: 0;
}

.border-dark{
  border-color: #cecece!important;
}

.highcharts-title{
  font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif;
  font-weight: 500;
  font-size: 15px !important;
}

#reports-siteright-datatable td:last-child{
  display: none;
}

#reports-siteright-datatable thead th:last-child{
  display: none;
}

#reports-siteright-datatable td:nth-last-child(2){
  display: none;
}

#reports-siteright-datatable thead th:nth-last-child(2){
  display: none;
}

#archived-siteright-datatable td:last-child{
  display: none;
}

#archived-siteright-datatable thead th:last-child{
  display: none;
}

#archived-siteright-datatable td:nth-last-child(1){
  display: none;
}

#archived-siteright-datatable thead th:nth-last-child(1){
  display: none;
}

.privacyDiv{
  background: #e2e2e2b3;
  text-align: center;
  margin-left: 12%;
  margin-right: 12%;
  margin-top: 10px;
  flex-grow: 6;
  flex: 1;
  padding: 30px;
  border: 1px solid #9c9c9c;
  box-shadow: 0px 0px 1px 1px #ffffff;
  overflow-y: scroll;
}

.cardMainDiv{
  width: 100%;
  margin-bottom: 4px;
  border: 3px solid #efeff6;
  box-shadow: 0 0 1px 1px #d4d4d4;
  padding: 0px;
}

.cardMainDiv.selected{
  border: 6px solid #adff00;
  box-shadow: 0 0 3px 1px #6d6d6d;
  margin-top: 6px;
  margin-bottom: 6px;
}

.cardMainDiv:hover{
  border: 3px solid #00f7c4;
  box-shadow: 0 0 1px 1px #6b6b6b;
}

.cardTextAddress{
  font-weight: 600;
  margin-right: 4px;
  padding: 2px;
  font-size: 0.94rem;
  margin: 4px;
  color: #646c73;
}

.colorPickerDiv{
  width: 48px;
  height: 24px;
  border-radius: 0px;
  box-shadow: 1px 1px 2px 0px #0c0c0c;
  margin-right: 10px;
}

.divCenter{
  max-width: 500px;
  margin: auto;
}

.row.form{
  justify-content: center;
  align-items: baseline;
}

.cheesebugerMenuOverlay{
  opacity: 0 !important;
}

.homePageTopBannerBackground{
  background: var(--officialDarkBlue);
  filter: alpha(opacity=80);
  -moz-opacity: 0.80;
  opacity: 0.80;
  position: absolute;
  left: 0;
  width: 100%;
}

.homePageTopBannerBackgroundMobile{
  background: var(--officialDarkBlue);
  filter: alpha(opacity=60);
  -moz-opacity: 0.60;
  opacity: 0.60;
  position: absolute;
  left: 0;
  width: 100%;
}

.homePageTopBannerContentContainer{
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.homePageTopBannerContentRow{
  height: 33.33333%;
  width: 100%;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  max-width: 450px;
  align-self: center;
  padding-right: 5px;
  padding-left: 5px;
  z-index: 1;
}

.homePageTopBannerContentRowText{
  display: inline-block;
  vertical-align: middle;
  font-family: officialFontSemiBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif !important;
  font-weight:100;
  color: var(--officialLightGray);
  text-align: center;
}

.homePageTopBannerTextTopRow{
  font-size: 35px;
  line-height: 30px !important;
}

.homePageTopBannerTextCenterRow{
  font-size: 20px;
  line-height: 25px;
}

.homePageTopBannerTextBottomRow{
  font-size: 28px;
  padding-left: 10px;
  padding-right: 10px;
  /* margin-bottom: -5px;
  margin-top: -5px; */
}

.homePageTopBannerButton{
  border: 2px solid var(--officialLightGray) !important;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  text-transform: none !important;
  height: 45px !important;
  border-radius: 0px !important;
}

.marketingButtonsContainer{
  display: flex;
  flex-direction: row;
}

.marketingButton{
  background-color: var(--officialDarkBlue);
  outline: none;
  cursor: pointer;
  width: 170px;
  margin-top: 10px
}

.marketingButtonText{
  font-size: 16px;
  font-family: officialFontSemiBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif !important;;
  padding-left: 10px;
  padding-right: 10px;
  color: var(--officialLightGray);
  text-align: center;
  height: 35px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  white-space: nowrap;
}

/* .homePageTopBannerButton:hover ~ .homePageTopBannerTextBottomRow {
  border: 2px solid #C3C3C3;
  color: #C3C3C3 !important;
} */

#lpLogoContainerHeader:hover{
  background-color: var(--hoverColor) !important;
}

.marketingNavbarContainer{
  width: 100%;
  background-color: var(--officialDarkBlue);
  align-items: center;
  align-content: center;
  height: '57px'
}

.marketingNavbarContainerInner{
  display: flex;
  flex-direction: row;
  margin-right: 0px;
  margin-left: 0px;
}

.marketingNavbarItemText{
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 20px;
  margin-left: 20px;
  color: var(--officialLightGray);
  font-size: 25px;
  font-family: officialFontSemiBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif !important;
  white-space: nowrap;
}

.marketingNavbarItemText:hover{
  color: var(--hoverColor);
}

.pricingPageHeader{
  margin-top: 35px; 
  font-family: officialFontBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
  font-size: 25px;
  text-align: center;
  color: var(--officialDarkBlue);
}

.pricingContainer{
  display: flex; 
  flex-direction: column;
  align-items: center;
}

.pricingWideRow{
  display: flex; 
  flex-direction: row;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  height: calc(100% -20px);
  max-width: 1330px
}

.pricingMobileColumn{
  width: 300px; 
}

.pricingMobileRow{
  display: flex; 
  flex-direction: row;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  max-width: 100%
}

.pricingMobileColumnBottom{
  border: 2px solid var(--hoverColor);
}

.pricingWideColumn{
  width: 20%; 
}
.pricingColumnInner{
  margin-right: 3px; 
  margin-left: 3px; 
  margin-bottom: 5px;
  background-color: var(--officialLightGray);
}
.pricingColumnTop{
  height: 80px;
  font-family: officialFontBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
  font-size: 20px;
  text-align: center;
  color: var(--officialDarkBlue);
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  border: 2px solid var(--hoverColor);
  padding: 5px
}
.pricingWideColumnBottom{
  height: calc(100% - 85px);
  max-height: 960px; 
  min-height: 600px;
  border: 2px solid var(--hoverColor);
}

.pricingPriceHeader{
  height: 80px;
  justify-content: center;
  align-content: center;
  display: flex;
  flex-direction: column;
}

.pricingPriceHeaderLabel{
  justify-content: center;
  align-content: center;
  display: flex;
  font-family: officialFontBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
  font-size: 20px;
  text-align: center;
  color: var(--officialDarkBlue);
}

.pricingPriceHeaderSublabel{
  justify-content: center;
  align-content: center;
  display: flex;
  font-family: officialFontSemiBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
  font-size: 15px;
  text-align: center;
  color: var(--officialDarkBlue);
}

.pricingHr{
  height:1px;
  width: 90%;
  border-width:1px;
  border-radius: 2px;
  border-color: var(--hoverColor);
  color: var(--hoverColor);
  background-color: var(--hoverColor)
}

.pricingPlanFeatureRow{
  display: flex;
  flex-direction: row;
  color: var(--officialDarkBlue);
  font-family: officialFontSemiBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
  font-size: 19px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.pricingPlanCheckMarkColumn{
  width: 25px;
}
.pricingPlanFeatureColumn{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.pricingPlanCheckMark{
  font-weight: bolder;
  font-size: 20px;
  color: var(--hoverColorDark);
}

.pricingFreeTrialButtonContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pricingFreeTrialButton{
  background-color: var(--officialDarkBlue);
  outline: none;
  cursor: pointer;
  width: 160px;
  margin-top: 10px
}

.pricingFreeTrialButtonText{
  font-size: 14px;
  font-family: officialFontSemiBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif !important;;
  padding-left: 10px;
  padding-right: 10px;
  color: var(--officialLightGray);
  text-align: center;
  height: 35px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  white-space: nowrap;
}

.productPageTitle{
  margin-left: 15px;
  margin-top: 35px;
}

.productPageDemoRequestAndButtonsContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.productsPageStoreButtonDiv{
  width: 230px;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.productsPageAppStoreButtonContainer{
  height: 40px;
  width: 115px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.productsPagePlayStoreButtonContainer{
  height: 40px;
  width: 115px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.productsPageAppStoreButtonImg{
  width: 100px;
}

.productsPagePlayStoreButtonImg{
  width: 110px;
}

.productPageMarketingButton{
  background-color: var(--officialDarkBlue);
  outline: none;
  cursor: pointer;
  width: 230px;
  margin-top: 25px
}

.productPageMarketingButtonTop{
  background-color: var(--officialDarkBlue);
  outline: none;
  cursor: pointer;
  width: 230px;
}

.productPageMarketingButtonText{
  font-size: 16px;
  font-family: officialFontSemiBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif !important;;
  padding-left: 10px;
  padding-right: 10px;
  color: var(--officialLightGray);
  text-align: center;
  height: 45px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  white-space: nowrap;
}

.productPageButtonPriceLabel{
  font-family: officialFontSemiBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif !important;
  font-size: 11px;
  color: var(--officialDarkBlue);
  text-align: center;
  height: 20px;
  width: 100%;
}

.productsPageDemoRequestContainer{
  width: 100%;
  border: 6px solid var(--officialDarkGray);
  background-color: var(--officialLightGray);
  padding: 20px;
}


.productsPageDemoRequestHeader{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 70px;
  font-family: officialFontSemiBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif !important;
  font-size: 25px;
  color: var(--officialDarkBlue);
  text-align: center;
  line-height: normal;
}

.productsPageDemoRequestSubHeader{
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: officialFontSemiBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif !important;
  font-size: 14px;
  color: var(--officialDarkBlue);
  text-align: center;
  padding: 10px
}

.productsPageDemoRequest-form-control-small {
  width: 95%;
  margin-left: 10px;

  display: block;
  padding: 0.275rem 0.55rem;
  font-size: 1rem;
  font-weight: 400;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border-bottom: 2px solid #bdbdbd;
  border-right: 0px;
  border-left: 0px;
  border-top: 0px;
  border-radius: 0px;
  transition: none;
}

.productsPageDemoRequest-form-control-small:focus { 
  color: #495057;
  background-color: #fff;
  border: 0px;
  outline: 0;
  box-shadow: none;
  border-bottom: 2px solid #095399;
}

.productsPageDemoRequestFormLabel{
  font-family: officialFontSemiBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif !important;
  font-size: 14px;
  color: var(--officialDarkBlue);
  margin-bottom: 0px;
  margin-top: 0px;
  white-space: nowrap;
  text-align: end;
}

.productsPageDemoRequestFormLabelContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100px;
}

.productsDemoRequestFormFieldContainer{
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 12px;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
}

.productsPageDemoRequestSubmitButton{
  width: 100%;
  height: 45px;
  background-color: var(--officialDarkBlue);
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: var(--officialLightGray);
  font-size: 18px;
  font-family:  officialFontSemiBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif !important;
  align-self: flex-end;
  cursor: pointer;
  margin-top: 30px;
}

.productsPageDemoRequestSubmitButtonSuccess{
  width: 100%;
  height: 45px;
  background-color: var(--officialDarkBlue);
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  justify-content: center;
  color: var(--officialLightGray);
  font-size: 22px;
  font-family:  officialFontSemiBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif !important;
  align-self: flex-end;
  cursor: pointer;
  margin-top: 30px;
}

.productsPageDemoRequestCancelButton{
  width: 100%;
  height: 45px;
  background-color: #747474;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: var(--officialLightGray);
  font-size: 18px;
  font-family:  officialFontSemiBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif !important;
  align-self: flex-end;
  cursor: pointer;
  margin-top: 4px;
}

.productPageUl{
  margin-top: 10px
}
.productPageUlRight{
  margin-left: 30px;
}

.productPageLi{
  font-size: 18px;
  color: var(--officialDarkBlue);
  line-height: normal;
  margin-left: -15px !important;
  margin-top: 10px;
  font-weight: 400;
}


.slick-prev, .slick-next {
  position: absolute;
  top: 50%;
}
.slick-prev {
  left: 3% !important;
  z-index: 1;
}
.slick-next {
  right: 3% !important;
  z-index: 1;
}
 .slick-next:before {
  font-size: 40px;
 }

 .slick-prev:before {
  font-size: 40px;
 }

.learningCenterThumbnail{
  height: 120px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.learningCenterCategoryLabel{
  color: var(--officialDarkBlue);
  font-size: 30px;
  font-family: officialFontBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif !important;;
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.learningCenterVideoDialogContainer{
  width: 100%;
  background-color: var(--hoverColorDark);
}

.learningCenterIntroVideoContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin-top: 20px
}

.learningCenterContainer{
  background-color: var(--officialLightGray);
}

.learningCenterVideoThumbStrip{
  background-color: var(--hoverColor); 
  padding-top: 10px; 
  padding-bottom: 10px;
}

.learningCenterTextThumbnail{
  background-color: var(--officialDarkBlue);
  font-family: officialFontSemiBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif !important;
  color: var(--officialLightGray);
  font-size: 20px;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 225px;
  height: 120px;
  padding: 10px;
  line-height: 24px;
}

.arcGisConnectingIconContainer{
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 18px;
  font-family:  officialFontSemiBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif !important;
  align-self: flex-end;
  margin-top: 60px;
}

.arcGisConnectingIconContainerSuccess{
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 18px;
  font-family:  officialFontSemiBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif !important;
  align-self: flex-end;
  margin-top: 60px;
  color: green
}

.arcGisConnectingIconContainerError{
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 18px;
  font-family:  officialFontSemiBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif !important;
  align-self: flex-end;
  margin-top: 60px;
  color: red
}



.accountFormtoLayerMappingGroupLoading{
  min-height: 60px ;
}
.accountFormtoLayerMappingGroup{
  margin-top: 10px;
  min-height: 30px;
  display: flex;
  flex-direction: row;
}


.dataExportSelectControl {
  max-width: 200px;
  margin-right: 5px;
  background-color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0px;
  color: #034f8e;
  margin-bottom: 10px
}

.dataExportSelectWkidControl {
  max-width: 200px;
  margin-right: 5px;
  background-color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0px;
  color: #034f8e;
  padding-left: 5px;
}

.dataExportSelectLabel {
  align-items: center;
  display: flex;
  margin: 0px;
  padding: 0px 8px 0px 0px;
  color: #3f576d;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
}

.officialFontBold{
  font-family: officialFontBold, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif !important;
}

.officialFont{
  font-family: officialFont, "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif !important;
}


.dropdown-check-list {
  display: inline-block;
}

.dropdown-check-list .anchor {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 5px 50px 5px 10px;
  border: 1px solid #ccc;
  width: 203px;
  margin-right: 5px;
  background-color: white;
  border-bottom : 2px solid #bdbdbd;
  color : #3f576d;
}

.dropdown-check-list .anchor:after {
  position: absolute;
  content: "";
  border-left: 2px solid black;
  border-top: 2px solid black;
  padding: 5px;
  right: 10px;
  top: 20%;
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.dropdown-check-list .anchor:active:after {
  right: 8px;
  top: 21%;
}

.dropdown-check-list ul.items {
  padding: 2px;
  display: none;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
}

.dropdown-check-list ul.items li {
  list-style: none;
}

.dropdown-check-list.visible .anchor {
  color: #0094ff;
}

.dropdown-check-list.visible .items {
  display: block;
  position: relative;
  background-color: white;
  width: 203px;
  max-height: 125px;
  overflow-y: scroll;
  z-index: 999999;
}



.dropdown-check-list-searchstrip {
  display: inline-block;
}

.dropdown-check-list-searchstrip .anchor {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 5px 50px 5px 10px;
  border: 1px solid #ccc;
  width: 203px;
  margin-right: 5px;
  background-color: white;
  border-bottom : 2px solid #bdbdbd;
  color : #3f576d;
}

.dropdown-check-list-searchstrip .anchor:after {
  position: absolute;
  content: "";
  border-left: 2px solid #7b7b7b;
  border-top: 2px solid #7b7b7b;
  padding: 3px;
  right: 10px;
  top: 15px;
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.dropdown-check-list-searchstrip .anchor:active:after {
  right: 8px;
  top: 21%;
}

.dropdown-check-list-searchstrip ul.items {
  padding: 2px;
  display: none;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
}

.dropdown-check-list-searchstrip ul.items li {
  list-style: none;
}

.dropdown-check-list-searchstrip.visible .anchor {
  color: #0094ff;
}

.dropdown-check-list-searchstrip.visible .items {
  display: block;
  position: relative;
  background-color: white;
  width: 203px;
  max-height: 125px;
  overflow-y: scroll;
  z-index: 999999;
}

.dropdown-check-list-searchstrip.visible .items {
  display: block;
  position: relative;
  background-color: white;
  width: 203px;
  max-height: 125px;
  overflow-y: scroll;
  z-index: 999999;
}

.dropdown-check-list-searchstrip.visible .items {
  display: block;
  position: absolute;
  background-color: white;
  width: 203px;
  max-height: 125px;
  overflow-y: scroll;
  z-index: 999999;
}

.count-pass {
  /* min-width: 300px; */
}

.chartTableMainDiv{
  height: 100%;
  border-top: 4px solid #d8d8d8;
}

.dashboardDownloadDiv{
  margin-top: -37px;
  padding-bottom: 37px;
  margin-left: -8px;
  width: 180px
}

.dateOptionDiv{
  cursor: pointer;
  height: 0px;
  margin-top: -5px;
  margin-left: 10px;
}

.dateOptionRadio{
  height: 10px;
  cursor: pointer;
}

.dateOptionLabel{
  margin-left: 5px;
  height: 0px;
  font-size: 13px;
  font-weight: 500;
  color: #555;
  cursor: pointer;
}

.menuDialogMapOption{
  margin: 2px;
  position: relative;
  flex-direction: column;
  display: flex;
  width: 100%;
}

.mapActionButton{
  top: 2px;
  position: absolute;
  height: 32px;
  margin-top: 4px;
  width: 82px;
  right: 4px;
  font-size: 12px;
  padding-top: 2px;
  color: rgb(95 95 95);
  font-weight: 700;
  text-shadow: 0px 0px 1px #dcdcdc;
  background: #dfdfe3;
}

.mapActionButtonIcon{
  margin-right: 6px;
}

.fullWidth{
  width: 100%;
  padding-top: 0px;
}

.modalDialogCustom{

}

.itemsFont{
  font-size: 13px;
  font-weight: 500;
  color: #7b7b7b;
}

.itemLine{
  padding: 0px;
}

.itemLine:hover{
  background-color: #DDD;
}

.mapMenuButton{
  padding-top: 5px;
}

.updateArea{
  font-size: 19px;
  color: #444;
  margin-top: 10px;
  margin-left: 10px;
}

.updateCat{
  font-size: 17px;
  text-transform: uppercase;
  margin-right: 10px;
  color: #888;
  width: 40px;
}
.updateFeat{
  font-size: 17px;
  margin-right: 10px;
  width: 180px;
  text-transform: uppercase;
  color: #13599d;
  font-weight: 600;
}
.updateDeat{
  font-size: 17px;
  text-transform: capitalize;
  color: #444;
  font-weight: 400;
}
.updateRow{
  display: flex;
  width: 100%;
  padding: 5px 10px 5px 10px;
}
.updateDiv{
  flex-direction: row;
  display: flex;
  width: 100%;
}

.buttonIcon.newReleaseIcon{
  margin: 0px;
  color: #ff743c;
  transform: scale(1.1);
  margin-left: 2px;
}

.updateUL{
  margin: 0px;
  list-style: square;
  padding-left: 30px;
}

.updateAppVersion{
  font-size: 15px;
  color: #db744a;
  margin-top: 10px;
  margin-left: 10px;
  text-transform: uppercase;
}